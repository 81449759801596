import React, { useEffect, useState } from "react";
import api from "../../shared/utils/api";
import FormGroupInput from "../../shared/components/FormGroupInput";
import FormGroupProvinceDropdownInput from "../../shared/components/FormGroupProvinceDropdownInput";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../../shared/Constants";
import PostalCodeInput from "../../shared/components/PostalCodeInput";
import HomeInspectionWaiver from "./HomeInspectionWaiver";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HomeInspectionPropertyType from "./HomeInspectionPropertyType";
import PhoneNumberInput from "../../shared/components/PhoneNumberInput";


export default function RequestHomeInspection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [validated, setValidated] = useState(false);
  const [homeOwnerEmail, setHomeOwnerEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [homeOwnerPhoneNumber, setHomeOwnerPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("BC");
  const [postalCode, setPostalCode] = useState("");
  const [territories, setTerritories] = useState([]);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState();
  const [agency, setAgency] = useState("");
  const [propertyType, setPropertyType] = useState("");

  // State for managing the Terms modal visibility
  const [showTermsModal, setShowTermsModal] = useState(false);

  const requestInspection = (homeOwnerEmail) => {
    // TODO: input sanitization
    api
      .post("/home-inspections", {
        //TODO: Audit ID will come from what type of Audit the user is requesting
        // since we don't have that we are going to hardcode it to 1
        auditID: 1,
        homeInspectionStatus: "Requested",
        homeOwnerEmailAddress: homeOwnerEmail,
        homeOwnerPhoneNumber:
        homeOwnerPhoneNumber.startsWith("+1")
        ? homeOwnerPhoneNumber?.replace(/[\(\)-]/g, "")
        : "+1" + homeOwnerPhoneNumber?.replace(/[\(\)-]/g, ""),
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        territoryId: +selectedTerritoryId,
        homeOwnerName: homeOwnerName,
        propertyType: propertyType
      })
      .then(
        (response) => {
          setAccessCode(response.homeInspectionAccessCode);
          setActiveIndex(1);
          toast.success("Your home assessment request was been successful!", {
            autoClose: 2000,
          });
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agencyParam = decodeURIComponent(params.get("agency"));
    if (agencyParam) {
      setAgency(agencyParam);
      const matchingTerritory = territories.find(
        (territory) => territory.name.toLowerCase() === agencyParam.toLowerCase()
      );
      if (matchingTerritory) {
        setSelectedTerritoryId(matchingTerritory.id);
      }
    }
  }, [territories]);

  const handleTerritoryChange = (e) => {
    setSelectedTerritoryId(e.target.value);
  };

  useEffect(() => {
    const requestUrl = "/territories-for-public";
    api.get(requestUrl).then(
      (response) => {
        const sortedTerritories = response.sort((a, b) => a.name.localeCompare(b.name));
        setTerritories(sortedTerritories);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Add email matching validation
    if (homeOwnerEmail !== confirmEmail) {
      toast.error("Email addresses must match");
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return; // Return if validation failed
    }

    setValidated(true);

    requestInspection(homeOwnerEmail);
  };

  const handleViewHomeInspection = (e) => {
    e.preventDefault();
    window.location.href = "/view-home-inspection";
  };

  const handleRequestAnotherHomeInspection = (e) => {
    e.preventDefault();
    setValidated(false);
    setHomeOwnerEmail("");
    setConfirmEmail("");
    setHomeOwnerPhoneNumber("");
    setHomeOwnerName("");
    setAddress("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setPropertyType("");
    setActiveIndex(0);
  };
  return (
    <div className="md:w-[75%]">
      <h2>Request A Home Assessment{agency ? " in " + agency : ""}.</h2>
      <p>
        Taking action to FireSmart your property will decrease the risk of
        wildfire damaging your home.
      </p>
      <p>
        During an assessment, a Wildfire Mitigation Specialist will provide a
        detailed work plan with recommendations specific to your property. For
        more information, please contact your local{" "}
        <a
          href="https://firesmartbc.ca/firesmart-coordinators/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          FireSmart Coordinator
        </a>{" "}
        in your community or{" "}
        <a className="underline" href="mailto:wmp.admin@FireSmartBC.ca">
          wmp.admin@FireSmartBC.ca
        </a>
      </p>
      <p>
        Please fill out your information below to request a home assessment:
      </p>

      {activeIndex === 0 ? (
        <HomeInspectionRequestForm
          homeOwnerEmail={homeOwnerEmail}
          setHomeOwnerEmail={setHomeOwnerEmail}
          confirmEmail={confirmEmail}
          setConfirmEmail={setConfirmEmail}
          homeOwnerName={homeOwnerName}
          setHomeOwnerName={setHomeOwnerName}
          homeOwnerPhoneNumber={homeOwnerPhoneNumber}
          setHomeOwnerPhoneNumber={setHomeOwnerPhoneNumber}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          province={province}
          setProvince={setProvince}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          handleSubmit={handleSubmit}
          validated={validated}
          handleTerritoryChange={handleTerritoryChange}
          selectedTerritoryId={selectedTerritoryId}
          territories={territories}
        />
      ) : (
        <HomeInspectionRequestReport
          homeOwnerEmail={homeOwnerEmail}
          accessCode={accessCode}
          handleViewHomeInspection={handleViewHomeInspection}
          handleRequestAnotherHomeInspection={
            handleRequestAnotherHomeInspection
          }
        />
      )}

    </div>
  );
}

// Rendered when activeIndex is 0
function HomeInspectionRequestForm({
  homeOwnerEmail,
  setHomeOwnerEmail,
  confirmEmail,
  setConfirmEmail,
  setHomeOwnerName,
  setHomeOwnerPhoneNumber,
  setAddress,
  setCity,
  province,
  postalCode,
  setProvince,
  setPostalCode,
  handleSubmit,
  validated,
  handleTerritoryChange,
  selectedTerritoryId,
  territories,
  setPropertyType,
}) {
  return (
    <div className="col">
      <div className="form-container">
        {/* <h4>Request Home Assessment Form</h4> */}
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="pt-3"
        >
          <Row className="flex flex-wrap">
            <Form.Group
              as={Col}
              controlId="territory"
              className="form-group lg:w-1/2 mb-3"
            >
              <Form.Label className="font-bold">
                What jurisdiction do you live in?
              </Form.Label>
              <Form.Select
                required
                className="form-select"
                onChange={handleTerritoryChange}
                value={selectedTerritoryId}
              >
                <option value="">Please select</option>
                {territories.map((territory) => (
                  <option key={territory.id} value={territory.id}>
                    {territory.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Must select one of the agencies listed.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 flex flex-wrap">
            <FormGroupInput
              controlId="homeOwnerEmail"
              label="Email Address"
              required
              type="email"
              invalidFeedback="Must provide a valid email."
              onChange={(e) => setHomeOwnerEmail(e.target.value)}
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupInput
              controlId="confirmHomeOwnerEmail"
              label="Confirm Email Address"
              required
              type="email"
              value={confirmEmail}
              invalidFeedback="Email addresses must match."
              onChange={(e) => setConfirmEmail(e.target.value)}
              onPaste={(e) => {
                e.preventDefault();
                toast.warn("Please type the email address again");
              }}
              isInvalid={confirmEmail !== "" && confirmEmail !== homeOwnerEmail}
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupInput
              controlId="homeOwnerName"
              label="Name"
              required
              type="text"
              invalidFeedback="Must provide a name."
              onChange={(e) => setHomeOwnerName(e.target.value)}
              className="lg:w-1/2 !flex-none mb-3"
            />

            <PhoneNumberInput
              controlId="homeOwnerPhoneNumber"
              label="Phone Number (Optional)"
              onChange={(e) => setHomeOwnerPhoneNumber(e)}
              required={false}
              invalidFeedback="Must provide a phone number in the format 123-456-7890."
              pattern="^\d{3}-\d{3}-\d{4}$"
              className="lg:w-1/2 !flex-none mb-3 col"
            />
            
            <FormGroupInput
              controlId="address"
              label="Address"
              required
              type="text"
              invalidFeedback="Must provide an address with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setAddress(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
              className="lg:w-1/2 mb-3"
            />
            <FormGroupInput
              controlId="city"
              label="City"
              required
              type="text"
              invalidFeedback="Must provide a city with allowed special characters .:-_', and allowed spaces."
              onChange={(e) => setCity(e.target.value)}
              pattern="^[a-zA-Z0-9 .:,-_']+$"
              className="lg:w-1/2 !flex-none mb-3"
            />
            <FormGroupProvinceDropdownInput
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              required
              placeHolderRequired
              className="lg:w-1/2 !flex-none mb-3"
            />
            <div className="col lg:w-1/2 !flex-none mb-3">
              <PostalCodeInput
                controlId="postalCode"
                label="Postal Code"
                required
                type="text"
                invalidFeedback="Must provide a postal code matching the A1A 1A1 pattern."
                value={postalCode}
                onChange={(e) => setPostalCode(e)}
                pattern="^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$"
              />
            </div>
            <HomeInspectionPropertyType setPropertyType={setPropertyType}/>
          </Row>

          {/* Checkbox for Terms and Conditions */}
          <Row className="mb-3">
            <Form.Group
              className="flex items-start"
              controlId="termsAndConditions"
            >
              <HomeInspectionWaiver text="I/we agree to the" />                  
            </Form.Group>
          </Row>
          {/* Checkbox for Property Owner */}
          <Row className="mb-3">
            <Form.Group
              className="flex items-start"
              controlId="termsAndConditions"
            >
              <Form.Check
                required
                type="checkbox"
                feedback="You must confirm that you are the property owner or have received permission from the owner."
                feedbackType="invalid"
                className="-mt-1 mr-2 "
              />
              <span className="flex-1">
                I confirm that I am the property owner or legal representative,
                or have received expressed permission from them to have this
                assessment conducted.
              </span>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="form-group">
              <Button className="btn-standard margin-right-10" type="submit">
                Request Home Assessment
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
}

// Rendered when activeIndex is 1
function HomeInspectionRequestReport({
  homeOwnerEmail,
  accessCode,
  handleViewHomeInspection,
  handleRequestAnotherHomeInspection,
}) {
  return (
    <div className="col">
      <div className="form-container">
        <h4>Home Assessment Request Report</h4>
        <p>Your home assessment request has been submitted!</p>
        <p>
          An email has been sent to the email address {homeOwnerEmail} with the
          access code {accessCode}.
        </p>
        <p>
          Use this access code with your email address to view your home
          assessment.
        </p>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleViewHomeInspection}
        >
          View Home Assessment
        </Button>
        <Button
          className="btn-standard margin-right-10"
          type="submit"
          onClick={handleRequestAnotherHomeInspection}
        >
          Request Another Home Assessment
        </Button>
      </div>
    </div>
  );
}