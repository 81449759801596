import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  ListFilter,
  ArrowUp,
  ArrowDown,
  FileSpreadsheet,
} from "lucide-react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender, 
} from "@tanstack/react-table";
import api from "../shared/utils/api";
import { toast } from "react-toastify";
import { ERROR_MESSAGE_DURATION } from "../shared/Constants";
import { LuPlusCircle } from "react-icons/lu";
import * as XLSX from "xlsx";

// Function to export table data to Excel
const exportToExcel = (table) => {
  // Get the filtered rows from the table
  const filteredRows = table.getFilteredRowModel().rows;

  // Prepare the data for the Excel file
  const exportData = filteredRows.map((row) => {
    const rowData = row.getVisibleCells().reduce((acc, cell) => {
      const columnHeader = cell.column.columnDef.header; // Column header
      const cellValue = cell.getValue(); // Get the actual value of the cell

      // Handle special cases like agency names or status
      // Ensure numbers are not converted to strings
      acc[columnHeader] =
        cell.column.columnDef.accessorKey === "agencyNames" && cellValue
          ? cellValue.split(",").join(", ")
          : cellValue;

      return acc;
    }, {});
    return rowData;
  });

  // Create a new workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(exportData);

  // Add header row styling
  ws["!rows"] = ws["!rows"] || [];
  ws["!rows"].push({ hpt: 20 }); // Increase header row height

  // Bold and center header row
  const headerRange = XLSX.utils.decode_range(ws["!ref"]);
  for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
    const headerCell = XLSX.utils.encode_cell({ r: 0, c: col });
    ws[headerCell].s = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };
  }

  // Add footer rows
  const footerRows = [
    { "": "Generated by FireSmart BC" },
    { "": `Generated on: ${new Date().toLocaleString()}` },
    { "": `Total Users: ${filteredRows.length}` },
  ];

  // Append footer rows
  XLSX.utils.sheet_add_json(ws, footerRows, {
    origin: -1, // Append at the end
    skipHeader: false, // Don't add another header
  });

  // Adjust column widths
  const colWidths = Object.keys(exportData[0] || {}).map((key) => {
    const headerLength = key.length;
    const maxDataLength = Math.max(
      ...exportData.map((row) => String(row[key] || "").length),
      headerLength,
    );
    return Math.min(Math.max(headerLength, maxDataLength) + 2, 30);
  });

  // Apply column widths
  ws["!cols"] = colWidths.map((width) => ({ wch: width }));

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Users");

  // Export the Excel file
  XLSX.writeFile(
    wb,
    `FireSmart_Users_${new Date().toISOString().split("T")[0]}.xlsx`,
  );
};

// Enhanced Multi-select Filter Component with Search
export const MultiSelectFilter = ({ options, selected, onChange, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [localSelected, setLocalSelected] = useState(selected);

  const filteredOptions = options.filter((option) => {
    const label = option.label || option;
    return label.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Apply filter immediately when localSelected changes
  useEffect(() => {
    onChange(localSelected);
  }, [localSelected, onChange]);

  return (
    <div className="p-4">
      {/* Search Input */}
      <div className="mb-3">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Search..."
        />
      </div>

      {/* Options List */}
      <div className="max-h-60 overflow-y-auto">
        {filteredOptions.map((option) => {
          const value = option.value || option;
          const label = option.label || option;
          return (
            <label
              key={value}
              className="flex items-center space-x-2 p-2 hover:bg-gray-50"
            >
              <input
                type="checkbox"
                checked={localSelected.includes(value)}
                onChange={(e) => {
                  const values = localSelected || [];
                  const newValues = e.target.checked
                    ? [...values, value]
                    : values.filter((v) => v !== value);
                  setLocalSelected(newValues);
                }}
                className="rounded border-gray-300"
              />
              <span>{label}</span>
            </label>
          );
        })}
      </div>

      {/* Buttons */}
      <div className="flex justify-end space-x-2 mt-4">
        <button
          onClick={() => {
            setLocalSelected([]);
          }}
          className="px-3 py-1 text-sm border rounded hover:bg-gray-100"
        >
          Clear
        </button>
        <button
          onClick={onClose} // Close the popover
          className="px-3 py-1 text-sm btn btn-black"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

// Reusable FilterPopover Component
export const FilterPopover = ({ column, isOpen, setIsOpen }) => {
  const popoverRef = useRef(null);
  const columnType = column.columnDef.type || "text";

  // Initialize localFilter based on columnType
  const initialFilter =
    columnType === "text" || columnType === "number"
      ? column.getFilterValue() || { operator: "contains", value: "" }
      : column.getFilterValue() || [];
  const [localFilter, setLocalFilter] = useState(initialFilter);
  // At the top of the component
  const [debouncedFilter, setDebouncedFilter] = useState(localFilter);

  // Update debouncedFilter after a delay when localFilter changes
  useEffect(() => {
    if (columnType === "text" || columnType === "number") {
      const handler = setTimeout(() => {
        setDebouncedFilter(localFilter);
      }, 300); // Adjust the delay as needed (e.g., 300ms)

      return () => {
        clearTimeout(handler);
      };
    }
  }, [localFilter, columnType]);

  // Apply the debounced filter
  useEffect(() => {
    if (columnType === "text" || columnType === "number") {
      if (
        debouncedFilter.value !== undefined &&
        debouncedFilter.value !== null &&
        debouncedFilter.value !== ""
      ) {
        column.setFilterValue(debouncedFilter);
      } else {
        column.setFilterValue(undefined);
      }
    }
  }, [debouncedFilter, columnType, column]);

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  // Apply filter immediately when localFilter changes
  useEffect(() => {
    if (columnType === "text" || columnType === "number") {
      if (
        localFilter.value !== undefined &&
        localFilter.value !== null &&
        localFilter.value !== ""
      ) {
        column.setFilterValue(localFilter);
      } else {
        column.setFilterValue(undefined);
      }
    }
  }, [localFilter, columnType, column]);

  if (!isOpen) return null;

  const clearFilter = () => {
    setLocalFilter(
      columnType === "text" || columnType === "number"
        ? { operator: "contains", value: "" }
        : [],
    );
    column.setFilterValue(undefined);
    setIsOpen(false);
  };

  return (
    <div
      ref={popoverRef}
      className="absolute mt-2 z-10 w-72 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      {columnType === "multiSelect" || columnType === "select" ? (
        <MultiSelectFilter
          options={column.columnDef.options || []}
          selected={column.getFilterValue() || []}
          onChange={(newValues) => {
            column.setFilterValue(
              newValues.length === 0 ? undefined : newValues,
            ); // Apply filter immediately
          }}
          onClose={() => setIsOpen(false)}
        />
      ) : (
        <div className="p-4">
          {/* Text or Number Filter */}
          {columnType === "text" && (
            <div className="space-y-2">
              <select
                value={localFilter.operator || "contains"}
                onChange={(e) =>
                  setLocalFilter((prev) => ({
                    ...prev,
                    operator: e.target.value,
                  }))
                }
                hidden={true}
                className="w-full p-2 border rounded"
              >
                <option value="contains">Contains</option>
              </select>
              <input
                type="text"
                value={localFilter.value || ""}
                onChange={(e) =>
                  setLocalFilter((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
                className="w-full p-2 pt-2.5 border rounded placeholder:text-gray-800 font-normal"
                placeholder="Search..."
              />
            </div>
          )}

          {/* Number Filter */}
          {columnType === "number" && (
            <div className="space-y-2">
              <select
                value={localFilter.operator || "equals"}
                onChange={(e) =>
                  setLocalFilter((prev) => ({
                    ...prev,
                    operator: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded"
              >
                <option value="equals">=</option>
                <option value="gt">&gt;</option>
                <option value="gte">≥</option>
                <option value="lt">&lt;</option>
                <option value="lte">≤</option>
              </select>
              <input
                type="number"
                value={localFilter.value || ""}
                onChange={(e) =>
                  setLocalFilter((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
                className="w-full p-2 pt-3 border rounded"
                placeholder="Enter number..."
              />
            </div>
          )}

          {/* Buttons */}
          <div className="flex justify-end space-x-2 mt-4">
            <button
              onClick={clearFilter}
              className="btn-standard btn btn-outline text-sm border rounded hover:bg-gray-100"
            >
              Clear
            </button>
            <button
              onClick={() => setIsOpen(false)} // Close the popover
              className=" text-sm btn btn-standard btn-black"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Column Header Component
export const ColumnHeader = ({ column }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Re-render when filter value changes
  const filterValue = column.getFilterValue();

  const handleFilterChange = useCallback((value) => {
    if (column.id === 'userStatus') {
      if (value && value.length > 0) {
        // Update URL with new status filters
        searchParams.set('status', value.map(s => encodeURIComponent(s)).join(','));
        setSearchParams(searchParams);
      } else {
        // Remove status param if no filters
        searchParams.delete('status');
        setSearchParams(searchParams);
      }
    }
  }, [column.id, searchParams, setSearchParams]);

  // Initialize filter from URL params on mount
  useEffect(() => {
    if (column.id === 'userStatus') {
      const statusParams = searchParams.get('status');
      if (statusParams) {
        const statuses = statusParams.split(',').map(s => decodeURIComponent(s));
        column.setFilterValue(statuses);
      }
    }
  }, [column, searchParams]);

  // Update URL when filter changes
  useEffect(() => {
    if (column.id === 'userStatus') {
      handleFilterChange(filterValue);
    }
  }, [column.id, filterValue, handleFilterChange]);

  return (
    <div className="relative">
      <div
        onClick={column.getToggleSortingHandler()}
        className="flex items-center justify-between cursor-pointer select-none min-h-[1.5rem]"
        style={{ userSelect: "none" }}
      >
        <span>{column.columnDef.header}</span>
        <div className="flex items-center">
          {column.getIsSorted() ? (
            <span className="ml-1">
              {column.getIsSorted() === "asc" ? (
                <ArrowUp size={16} />
              ) : (
                <ArrowDown size={16} />
              )}
            </span>
          ) : null}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsFilterOpen(!isFilterOpen);
            }}
            className={`p-1 ml-2 rounded hover:bg-gray-100 ${
              filterValue &&
              ((Array.isArray(filterValue) && filterValue.length > 0) ||
                (!Array.isArray(filterValue) && filterValue.value))
                ? "!text-primary"
                : ""
            }`}
          >
            <ListFilter size={16} />
          </button>
        </div>
      </div>
      <FilterPopover
        column={column}
        isOpen={isFilterOpen}
        setIsOpen={setIsFilterOpen}
      />
    </div>
  );
};

function Users({apiUrl = '/firesmart-users'}) {
  const [users, setUsers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const navigate = useNavigate();

  // Fetch users
  // useEffect(() => {
  //   const fetchUsers = () => {
  //     api.get("/firesmart-users").then(
  //       (response) => {
  //         if (response) setUsers(response);
  //       },
  //       (error) => {
  //         toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
  //       },
  //     );
  //   };
  //   fetchUsers();
  // }, []);
  useEffect(() => {
    if (apiUrl) {
      api.get(apiUrl).then(
        (response) => setUsers(response || []),
        (error) => toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION })
      );
    }
  }, [apiUrl]);

  // Fetch agencies
  useEffect(() => {
    api.get("/territories").then(
      (response) => {
        const agencyOptions = response
          .filter((territory) => territory.id !== 1)
          .map((territory) => ({
            value: territory.name,
            label: territory.name,
          }));
        setAgencies(agencyOptions);
      },
      (error) => {
        toast.error("Failed to load territories. Error: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  }, []);

  const getBadge = (status) => {
    const baseClasses = "text-sm px-3 pt-2 pb-1 rounded-full";
    return status === "Active"
      ? `${baseClasses} bg-green-200 text-green-800`
      : `${baseClasses} bg-blue-200 text-blue-800`;
  };

  const filterFn = (row, columnId, filterValue) => {
    if (
      !filterValue ||
      (Array.isArray(filterValue) && filterValue.length === 0)
    ) {
      return true;
    }

    const cellValue = row.getValue(columnId);

    if (Array.isArray(filterValue)) {
      // Handle 'select' and 'multiSelect' filters
      if (columnId === "agencyNames") {
        const agencyList = (cellValue || "").split(",").map((v) => v.trim());
        return filterValue.some((selectedAgency) =>
          agencyList.includes(selectedAgency),
        );
      } else {
        // For other columns like 'userStatus'
        return filterValue.includes(cellValue);
      }
    } else {
      // Handle 'text' and 'number' filters
      const { operator, value } = filterValue;
      if (operator && value !== undefined && value !== null) {
        const cellValueStr = String(cellValue || "").toLowerCase();
        const filterValueStr = String(value).toLowerCase();
        switch (operator) {
          case "contains":
            return cellValueStr.includes(filterValueStr);
          case "equals":
            return cellValueStr === filterValueStr;
          case "startsWith":
            return cellValueStr.startsWith(filterValueStr);
          case "endsWith":
            return cellValueStr.endsWith(filterValueStr);
          case "gt":
            return Number(cellValue) > Number(value);
          case "gte":
            return Number(cellValue) >= Number(value);
          case "lt":
            return Number(cellValue) < Number(value);
          case "lte":
            return Number(cellValue) <= Number(value);
          default:
            return true;
        }
      }
      return true;
    }
  };

  const userRoles = [
    { value: "Admin", label: "Admin" },
    { value: "Program Manager", label: "Program Manager" },
    { value: "Coordinator", label: "Coordinator" },
    { value: "User", label: "User" },
  ];

  // Updated columns definition
  const columns = useMemo(
    () => [
      {
        accessorKey: "firstname",
        header: "First Name",
        type: "text",
        filterFn: filterFn,
      },
      {
        accessorKey: "lastname",
        header: "Last Name",
        type: "text",
        filterFn: filterFn,
      },
      {
        accessorKey: "email",
        header: "Email",
        type: "text",
        filterFn: filterFn,
      },
      {
        accessorKey: "agencyNames",
        header: "Agencies",
        type: "multiSelect",
        options: agencies, // agencies is already an array of objects with 'value' and 'label'
        cell: ({ getValue }) => {
          const agencies = getValue();
          return agencies ? (
            <div className="flex flex-wrap gap-1">
              {agencies.split(",").map((agency, index) => (
                <span
                  key={index}
                  className="bg-gray-100 px-2 py-1 rounded text-sm"
                >
                  {agency.trim()}
                </span>
              ))}
            </div>
          ) : null;
        },
        filterFn,
      },
      {
        accessorKey: "userRoleName", // Accessor for user roles
        header: "Level",
        type: "select", // Specify it's a selectable column
        options: userRoles,
        filterFn: filterFn, // Use the same filterFn logic
      },
      {
        accessorKey: "userStatus",
        header: "Status",
        type: "select",
        options: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
        ],
        cell: ({ getValue }) => (
          <span className={getBadge(getValue())}>{getValue()}</span>
        ),
        filterFn: filterFn,
      },
    ],
    [agencies],
  );

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div className="pt-2">
      {/* Header */}
      <Row className="mb-4">
        <Col md={6}>
          <h2>Users</h2>
        </Col>
        
        <Col md={6} className="text-right" hidden={apiUrl !== '/firesmart-users'}>
        <button
            className="btn-standard btn-outline btn mr-2"
            onClick={() => exportToExcel(table)}
          >
            <FileSpreadsheet className="mr-1 -mt-0.5 h-4 w-4 inline-block" />{" "}
            Export to Excel
          </button>
          <button className="btn-standard btn" onClick={() => navigate("add")}>
            <LuPlusCircle className=" -mt-0.5 mr-1 h-4 w-4 inline-block" /> 
            New User
          </button>
         
        </Col>
      </Row>

      {/* Table */}
      <table id="users-table" className="min-w-full table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-2 text-left font-bold text-gray-700 relative"
                >
                  {header.isPlaceholder ? null : (
                    <ColumnHeader column={header.column} />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => apiUrl !== '/firesmart-users' ? navigate('/users/'+row.original.userID.toString()) : navigate(row.original.userID.toString())}
              className="hover:bg-gray-100 cursor-pointer"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-4 py-2 whitespace-nowrap">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border rounded-l disabled:opacity-50"
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border-t border-b disabled:opacity-50"
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border rounded-r disabled:opacity-50"
          >
            {">>"}
          </button>
          <span className="ml-2">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
        </div>
        <div>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            className="ml-4 border p-1 rounded"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default Users;
