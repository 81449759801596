import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Module from "./Module";
import api from "../../../shared/utils/api";
import _ from "lodash";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ERROR_MESSAGE_DURATION } from "../../../shared/Constants";
import { AiOutlineSave, AiOutlineCheck } from "react-icons/ai";

import {
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import DownloadPdfButton from "../../../shared/components/DownloadPdfButton";
import PdfCover from "../../../shared/components/PdfCover";
import { isUserAdmin } from "../../../shared/utils/authToken";

const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

// hooks
const useScrollspy = (ids, offset = 0) => {
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

function AuditForm() {
  const [audit, setAudit] = useState({});
  const [auditID, setAuditID] = useState(0);
  const [homeInspectionStatus, setHomeInspectionStatus] = useState("");
  const [currentQuestionID, setCurrentQuestionID] = useState(null);

  const navigate = useNavigate();

  const showSupervisorComments =
    homeInspectionStatus === "Approval Required" ||
    homeInspectionStatus === "Changes Requested" ||
    homeInspectionStatus === "Changes Submitted";

  const modIDs =
    audit.auditModules?.map((module) => "mod-" + module.auditModuleID) || [];

  const activeID = useScrollspy(modIDs);

  let location = useLocation();
  let state = location.state;

  const params = useParams();

  const { id: homeInspectionID } = params;

  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const setupAuditFormData = (auditForm, auditResults) => {
    return {
      auditID: auditForm.auditID,
      auditTitle: auditForm.auditTitle,
      auditModules: auditForm.auditModules.map((module) => {
        return {
          auditModuleID: module.auditModuleID,
          moduleTitle: module.moduleTitle,
          hexCode: module.hexCode,
          auditQuestions: module.auditQuestions?.map((auditQuestion) => {
            const commentData =
              _.find(
                auditResults?.comments,
                (comment) =>
                  comment?.questionID === auditQuestion.auditQuestionID,
              ) || {};

            const images = _.filter(
              auditResults?.images,
              (image) => image?.questionID === auditQuestion.auditQuestionID,
            );

            return {
              auditQuestionID: auditQuestion.auditQuestionID,
              question: auditQuestion.question,
              questionDescription: auditQuestion.questionDescription,
              auditQuestionOptions: auditQuestion.auditQuestionOptions?.map(
                (answer) => {
                  return {
                    auditQuestionOptionID: answer.auditQuestionOptionID,
                    header: answer.header,
                    option: answer.option,
                    sortOrder: answer.sortOrder,
                    selected: _.includes(
                      auditResults?.questionOptionIDs,
                      answer.auditQuestionOptionID,
                    ),
                  };
                },
              ),
              defaultComment: auditQuestion.defaultComment,
              comment: commentData.comment || "",
              homeOwnerComment: commentData.homeOwnerComment || "",
              supervisorComment: commentData.supervisorComment || "",
              supervisorCommentStatus:
                commentData.supervisorCommentStatus || "",
              homeInspectionCommentID:
                commentData.homeInspectionCommentID || "",
              requiresAction: commentData.requiresAction || false,
              images: images || [],
              sortOrder: auditQuestion.sortOrder,
            };
          }),
        };
      }),
    };
  };

  const setCommentCallback = (questionID, comment) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updatedAudit = _.cloneDeep(audit);
    _.set(
      updatedAudit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].comment`,
      comment,
    );
    setAudit(updatedAudit);
  };

  const setSupervisorCommentCallback = (questionID, comment) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updatedAudit = _.cloneDeep(audit);
    _.set(
      updatedAudit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].supervisorComment`,
      comment,
    );
    setAudit(updatedAudit);
  };

  const setRequiresActionCallback = (questionID, requiresAction) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const updatedAudit = _.cloneDeep(audit);
    _.set(
      updatedAudit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].requiresAction`,
      requiresAction,
    );
    setAudit(updatedAudit);

    // Update unresolvedQuestions based on requiresAction
    handleStatusUpdate(questionID, requiresAction ? "UnResolved" : "Resolved");
  };

  const setQuestionOptionsCallback = (questionID, answerIDs) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);

    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );
    const aqo = questionOptions[auditQuestionIndex].auditQuestionOptions;

    aqo.forEach((answer) => {
      answer.selected = answerIDs.includes(answer.auditQuestionOptionID);
    });

    const updatedAudit = _.cloneDeep(audit);
    _.set(
      updatedAudit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].auditQuestionOptions`,
      aqo,
    );

    setAudit(updatedAudit);
  };

  const removeImageCallback = (questionID, imageID) => {
    api.delete("/home-inspection-images/" + imageID).then(
      () => {
        toast.success("Image removed successfully", { autoClose: 2000 });
        removeImageFromAuditData(questionID, imageID);
      },
      (error) => {
        toast.error("Error removing image: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
        // TODO: how do we make the image preview show up again?
      },
    );
  };

  const removeImageFromAuditData = (questionID, imageID) => {
    const auditModuleIndex = getAuditModuleIndexByQuestionID(questionID);
    const questionOptions = audit.auditModules[auditModuleIndex].auditQuestions;
    const auditQuestionIndex = _.findIndex(
      questionOptions,
      (question) => question.auditQuestionID === questionID,
    );

    const newImgs = audit.auditModules[auditModuleIndex].auditQuestions[
      auditQuestionIndex
    ].images.filter((image) => image.imageID !== imageID);

    const updatedAudit = _.cloneDeep(audit);
    _.set(
      updatedAudit,
      `auditModules[${auditModuleIndex}].auditQuestions[${auditQuestionIndex}].images`,
      newImgs,
    );
    setAudit(updatedAudit);
  };

  const formatDataForSubmit = () => {
    let inspectionResults = audit.auditModules.map((module) =>
      module.auditQuestions?.map((question) => {
        return {
          questionID: parseInt(question.auditQuestionID),
          questionOptionIDs: question.auditQuestionOptions
            ?.filter((option) => option.selected)
            .map((option) => option.auditQuestionOptionID),
          comment: question.comment,
          requiresAction: question.requiresAction,
          supervisorComment: question.supervisorComment,
        };
      }),
    );

    inspectionResults = _.flatten(inspectionResults);
    return {
      homeInspectionID: isOffline ? homeInspectionID : parseInt(homeInspectionID),
      results: inspectionResults,
    };
  };

  const inspectionCompleted =
    homeInspectionStatus === "Completed" ||
    homeInspectionStatus === "Approval Required" ||
    homeInspectionStatus === "Changes Requested" ||
    homeInspectionStatus === "Changes Submitted";

  const inspectionReadOnly = isUserAdmin()
    ? false
    : homeInspectionStatus === "Completed" ||
    homeInspectionStatus === "Delivered" ||
    homeInspectionStatus === "Certified";

  const saveInspection = (callBack) => {
    const formatted = formatDataForSubmit();
    api.post("/home-inspection-results/submit", formatted).then(
      () => {
        if (callBack) {
          callBack();
        } else {
          toast.success("Assessment saved successfully");
        }
      },
      (error) => {
        toast.error("Error saving assessment: " + error, {
          autoClose: ERROR_MESSAGE_DURATION,
        });
      },
    );
  };

  const completeInspection = () => {
    saveInspection(submitApprovalRequired);
  };

  const submitApprovalRequired = () => {
    api
      .post("/home-inspection-approval-required", {
        targetHomeInspectionID: isOffline ? homeInspectionID : parseInt(homeInspectionID),
      })
      .then(
        (response) => {
          toast.success("Assessment completed successfully");
          if (response) {
            setHomeInspectionStatus(response.homeInspectionStatus);
            navigate("/home-inspections");
          }
        },
        (error) => {
          toast.error("Error completing assessment: " + error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
  };

  const promptCompleteInspection = (e) => {
    e.preventDefault();
    // Confirm the user wants to complete the inspection

    const formData = formatDataForSubmit();
    const answeredQuestionCount = formData.results?.filter(
      (f) => f.questionOptionIDs?.length,
    )?.length;
    const questionsRequiringAnswerCount = audit.auditModules.reduce(
      (acc, module) => {
        return (
          acc +
          module.auditQuestions.filter(
            (q) => q.auditQuestionOptions && q.auditQuestionOptions.length,
          ).length
        );
      },
      0,
    );

    if (answeredQuestionCount !== questionsRequiringAnswerCount) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-dialog">
              <h3>Missing Answers</h3>
              <p>
                Not all questions have an answer. Please fill them in before
                submitting for approval.
              </p>
              <button
                className="btn btn-standard float-right"
                onClick={() => {
                  onClose();
                }}
              >
                Ok
              </button>
            </div>
          );
        },
      });
      return;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-dialog">
            <h3>Submit Assessment</h3>
            <p>Are you sure you would like to submit this assessment for review?</p>
            <button
              className="btn btn-standard float-right"
              onClick={() => {
                completeInspection();
                onClose();
              }}
            >
              Submit
            </button>
            <button
              className="btn btn-standard secondary margin-right-10 float-right"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const getAuditModuleIndexByQuestionID = (questionID) => {
    return _.findIndex(audit.auditModules, (module) => {
      return (
        _.findIndex(
          module.auditQuestions,
          (question) => question.auditQuestionID === questionID,
        ) > -1
      );
    });
  };

  // Keypress listener to close fullscreen image
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      closeFullScreen();
    }
  };

  const closeFullScreen = () => {
    var fullpage = document.getElementById("fullpage");
    if (fullpage) {
      fullpage.style.display = "none";
    }
  };

  useEffect(() => {
    if (auditID === 0) return;
    // if (homeInspectionID === 0) return;

    const promiseAuditInfo = new Promise((resolve, reject) => {
      api.get("/audit-form/" + auditID).then(
        (response) => {
          if (response) {
            resolve(response);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });

    const promiseAuditResults = new Promise((resolve, reject) => {
      api.get("/home-inspection-results/" + homeInspectionID).then(
        (response) => {
          if (response) {
            resolve(response);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });

    const fetchData = () => {
      Promise.all([promiseAuditInfo, promiseAuditResults])
        .then((values) => {
          var auditInfo = values[0];
          var auditResults = values[1];

          var tempAudit = setupAuditFormData(auditInfo, auditResults);
          setAudit(tempAudit);

          // Initialize unresolvedQuestions
          const unresolved = tempAudit.auditModules.flatMap((module) =>
            module.auditQuestions
              .filter((q) => q.requiresAction)
              .map((q) => parseInt(q.auditQuestionID)),
          );
          setUnresolvedQuestions(unresolved);
        })
        .catch((error) => {
          toast.error("Error fetching audit data: " + error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        });
    };

    const fetchHomeInspectionStatus = () => {
      api.get("/home-inspection/" + homeInspectionID).then(
        (response) => {
          if (response) {
            setHomeInspectionStatus(response.homeInspectionStatus);
          }
        },
        (error) => {
          toast.error("Error fetching home assessment status: " + error, {
            autoClose: ERROR_MESSAGE_DURATION,
          });
        },
      );
    };

    fetchData();
    fetchHomeInspectionStatus();
  }, [auditID, homeInspectionID]);

  useEffect(() => {
    if (state) {
      setAuditID(state.auditID);
    }

    if (!state) {
      // TODO: go back to previous page or look up audit ID from API
    }

    window.onkeydown = handleKeyPress;
  }, []);

  const [unresolvedQuestions, setUnresolvedQuestions] = useState([]);

  const useUnresolvedScrollspy = (unresolvedQuestions, offset = 0) => {
    const [nextUnresolvedId, setNextUnresolvedId] = useState(null);
    const [prevUnresolvedId, setPrevUnresolvedId] = useState(null);
    const [currentUnresolvedId, setCurrentUnresolvedId] = useState(null);

    const computeNextPrev = () => {
      const scroll = window.pageYOffset;
      let nextId = null;
      let prevId = null;
      let currentId = null;

      // Find current active unresolved question
      for (let i = 0; i < unresolvedQuestions.length; i++) {
        const id = unresolvedQuestions[i];
        const element = document.getElementById(`question-${id}`);
        if (!element) continue;

        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + scroll - offset;
        const elementBottom = rect.bottom + scroll - offset;

        if (isBetween(scroll, elementTop, elementBottom)) {
          currentId = id;
          break;
        }
      }

      setCurrentUnresolvedId(currentId);

      if (currentId !== null) {
        const currentIndex = unresolvedQuestions.indexOf(currentId);
        if (currentIndex > -1) {
          if (currentIndex < unresolvedQuestions.length - 1) {
            nextId = unresolvedQuestions[currentIndex + 1];
          }
          if (currentIndex > 0) {
            prevId = unresolvedQuestions[currentIndex - 1];
          }
        }
      } else {
        // If no currentId is found, determine nextId based on scroll position
        for (let i = 0; i < unresolvedQuestions.length; i++) {
          const id = unresolvedQuestions[i];
          const element = document.getElementById(`question-${id}`);
          if (!element) continue;

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);

          if (scroll < top) {
            nextId = id;
            if (i > 0) {
              prevId = unresolvedQuestions[i - 1];
            }
            break;
          }
        }
      }

      setNextUnresolvedId(nextId);
      setPrevUnresolvedId(prevId);
    };

    useLayoutEffect(() => {
      computeNextPrev();

      window.addEventListener("resize", computeNextPrev);
      window.addEventListener("scroll", computeNextPrev);

      return () => {
        window.removeEventListener("resize", computeNextPrev);
        window.removeEventListener("scroll", computeNextPrev);
      };
    }, [unresolvedQuestions, offset]);

    return { nextUnresolvedId, prevUnresolvedId, currentUnresolvedId };
  };

  useUnresolvedScrollspy(unresolvedQuestions);

  const handleStatusUpdate = (questionID, newStatus) => {
    setUnresolvedQuestions((prevUnresolvedQuestions) => {
      if (newStatus === "UnResolved") {
        // Add the questionID only if it's not already in the array
        if (!prevUnresolvedQuestions.includes(questionID)) {
          return [...prevUnresolvedQuestions, questionID].sort((a, b) => a - b);
        }
        return prevUnresolvedQuestions;
      } else {
        // Remove the questionID if it exists in the array
        return prevUnresolvedQuestions
          .filter((id) => id !== questionID)
          .sort((a, b) => a - b);
      }
    });
  };

  const [questionRefs, setQuestionRefs] = useState({});

  useEffect(() => {
    const refs = {};
    unresolvedQuestions.forEach((id) => {
      // Adapt the key to match 'question-${id}' format
      const questionId = `question-${id}`;
      refs[questionId] = React.createRef();
    });
    setQuestionRefs(refs);
  }, [unresolvedQuestions]);

  const { nextUnresolvedId, prevUnresolvedId, currentUnresolvedId } =
    useUnresolvedScrollspy(unresolvedQuestions);

  const handlePrevious = () => {
    if (prevUnresolvedId) {
      scrollToQuestion(prevUnresolvedId);
      setCurrentQuestionID(prevUnresolvedId);
    } else {
      console.log("No previous unresolved question found.");
    }
  };

  const handleNext = () => {
    if (nextUnresolvedId) {
      scrollToQuestion(nextUnresolvedId);
      setCurrentQuestionID(nextUnresolvedId);
    } else {
      console.log("No next unresolved question found.");
    }
  };

  const scrollToQuestion = (questionID) => {
    const element = document.getElementById(`question-${questionID}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      // Optionally, set focus for accessibility
      element.focus({ preventScroll: true });
    } else {
      console.log(`Element with ID question-${questionID} not found.`);
    }
  };

  const contentRef = useRef();

  return (
    <div ref={contentRef}>
      <PdfCover />
      <div className="mb-12 lg:mb-0 flex items-center justify-between col-md-8 col-lg-9">
        <h2 className="mb-6 md:mb-8 mt-2 pdf-audit-title">
          {audit.auditTitle}
        </h2>
        <DownloadPdfButton contentRef={contentRef} />
      </div>
      <Row>
        <Col className="col-md-8 col-lg-9">
          {audit.auditModules &&
            audit.auditModules.map((module, index) => {
              const displayTitle = `${String.fromCharCode(65 + index)}) ${module.moduleTitle}`;

              return (
                <Module
                  key={`mod-${module.auditModuleID}`}
                  moduleID={module.auditModuleID}
                  moduleTitle={displayTitle} // Use the new variable for display title
                  moduleQuestions={module.auditQuestions}
                  hexCode={module.hexCode}
                  setCommentCallback={setCommentCallback}
                  setSupervisorCommentCallback={setSupervisorCommentCallback}
                  setQuestionOptionsCallback={setQuestionOptionsCallback}
                  setRequiresActionCallback={setRequiresActionCallback}
                  removeImageCallback={removeImageCallback}
                  auditID={auditID}
                  homeInspectionID={homeInspectionID}
                  className={index !== 0 ? "mt-[75px]" : ""}
                  updateStatusesCallback={handleStatusUpdate}
                  questionRefs={questionRefs}
                  saveInspection={saveInspection}
                  showSupervisorComment={showSupervisorComments}
                  inspectionReadOnly={inspectionReadOnly}
                />
              );
            })}
        </Col>
        <Col md={3}>
          <div
            id="inspection-minimap"
            className="bottom-[20px] left-0 md:left-auto px-4 md:bottom-auto"
          >
            <div className="hidden md:block">
              {inspectionCompleted && (
                <div className="border-border border rounded-md md:px-1.5 md:py-2.5 lg:px-3 lg:py-5 mb-4 shadow-sm">
                  {unresolvedQuestions.length > 0 && (
                    <h5 className="flex flex-row items-start flex-nowrap">
                      <IoIosInformationCircleOutline className="h-7 w-7 inline-block text-orange-600 shrink-0 -mt-1 mr-1" />
                      <div>
                        <div>
                          {unresolvedQuestions.length} question
                          {unresolvedQuestions.length > 1 ? "s" : ""} need
                          {unresolvedQuestions.length > 1 ? "" : "s"} to be
                          resolved.
                        </div>
                        <div className="flex flex-row items-start gap-2 ml-auto mt-2">
                          <button
                            className={`btn btn-standard btn-outline mr-1 ${!prevUnresolvedId
                                ? "!text-gray-300 !border-gray-300 cursor-not-allowed"
                                : ""
                              }`}
                            onClick={handlePrevious}
                            disabled={!prevUnresolvedId}
                          >
                            <IoIosArrowBack className="-mt-0.5 inline-block" />
                          </button>
                          <button
                            className={`btn btn-standard btn-outline ${!nextUnresolvedId
                                ? "!text-gray-300 !border-gray-300 cursor-not-allowed"
                                : ""
                              }`}
                            onClick={handleNext}
                            disabled={!nextUnresolvedId}
                          >
                            <IoIosArrowForward className="-mt-0.5 inline-block" />
                          </button>
                        </div>
                      </div>
                    </h5>
                  )}
                  {unresolvedQuestions.length === 0 && (
                    <div className="mt-2">
                      {" "}
                      <h5 className="flex flex-row items-start flex-nowrap">
                        <AiOutlineCheck className="mr-1.5 inline-block text-green-600 h-7 w-7 inline-block shrink-0 -mt-1 mr-1" />{" "}
                        All questions currently resolved
                      </h5>
                    </div>
                  )}
                </div>
              )}
              <h4>Navigation</h4>
              {audit.auditModules &&
                audit.auditModules.map((module, index) => {
                  const displayTitle = `${String.fromCharCode(65 + index)}) ${module.moduleTitle}`; // Create a new variable for display title
                  return (
                    <div key={`mod-${module.auditModuleID}`}>
                      <a
                        href={"#" + displayTitle}
                        className={
                          activeID === "mod-" + module.auditModuleID
                            ? "active"
                            : ""
                        }
                      >
                        {displayTitle}
                      </a>
                    </div>
                  );
                })}
            </div>
            {!inspectionReadOnly && (
              <div className="flex w-full justify-between md:block pdf-hide">
                <button
                  className="btn btn-standard btn-black margin-right-10 margin-top-20"
                  onClick={() => saveInspection()}
                >
                  <AiOutlineSave className="mr-1.5 inline-block" /> Save
                </button>
                {!inspectionCompleted && (
                  <button
                    className="btn btn-standard margin-top-20"
                    onClick={promptCompleteInspection}
                  >
                    <IoIosCheckmarkCircleOutline className="mr-1.5 inline-block" />{" "}
                    Submit for review
                  </button>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div id="fullpage" onClick={closeFullScreen}></div>
    </div>
  );
}

export default AuditForm;
