import React from "react";
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from "../../../shared/utils/api";
import FormControlInput from "../../../shared/components/FormControlInput";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function AuditQuestionOption() {
    const [header, setHeader] = useState('');
    const [option, setOption] = useState('');
    const [sortOrder, setSortOrder] = useState(0);
    const [requiresAction, setRequiresAction] = useState(false);

    const navigate = useNavigate();

    const params = useParams();

    const { questionID, optionID } = params;

    const fetchQuestionOption = () => {
        api.get('/audit-question-options/' + optionID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setHeader(response.header);
                setOption(response.option);
                setSortOrder(response.sortOrder);
                setRequiresAction(response.requiresAction);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchQuestionOption()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audit-question-options', {
            auditQuestionID: parseInt(questionID),
            auditQuestionOptionID: parseInt(optionID),
            header: header,
            option: option,
            sortOrder: parseInt(sortOrder),
            requiresAction: requiresAction
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/audit-question-options/' + optionID).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <form className="form-container" onSubmit={handleSubmit}>
                <h4 style={{ marginBottom: '24px', fontSize: '20px' }}>{option}</h4>
                <FormControlInput label="Header" type="text" value={header} onChange={(e) => setHeader(e.target.value)} />
                <FormControlInput label="Option" type="text" value={option} onChange={(e) => setOption(e.target.value)} />
                <FormControlInput label="Sort Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <div className="form-group" style={{ margin: '24px 0', padding: '12px', backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer', fontSize: '16px' }}>
                        <input
                            type="checkbox"
                            checked={requiresAction}
                            onChange={(e) => setRequiresAction(e.target.checked)}
                            style={{ width: '20px', height: '20px', cursor: 'pointer', accentColor: '#dc3545' }}
                        />
                        Requires Action
                    </label>
                </div>
                <div style={{ display: 'flex', gap: '12px', marginTop: '24px' }}>
                    <button type="submit" className="btn-standard success">Save</button>
                    <button type="button" className="btn-standard danger" onClick={handleDelete}>Delete</button>
                </div>
            </form>
        </div>
    );
}

export default AuditQuestionOption;
