import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader2, CheckCircle2, AlertTriangle } from 'lucide-react';
import Footer from './Footer';
import Header from './Header';
import api from '../shared/utils/api';
import DatabaseService from '../shared/idpHelper';
import { getLoggedInUser } from '../shared/utils/authToken';
import LoaderProvider from './Loader/LoaderProvider';

export default function Root({ isLoggedIn, logoutCallBack }) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [currentInspection, setCurrentInspection] = useState(null);
  const [syncError, setSyncError] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingInspections, setPendingInspections] = useState([]);
  const [syncResults, setSyncResults] = useState({
    total: 0,
    successful: 0,
    failed: 0,
    syncedInspections: []
  });

  useEffect(() => {
    const checkOfflineInspections = async () => {
      if (isLoggedIn) {
        try {
          const inspections = await DatabaseService.getAllItems('homeInspections');
          if (inspections.length > 0) {
            setPendingInspections(inspections);
            setShowConfirmDialog(true);
          }
        } catch (error) {
          console.error('Error checking offline inspections:', error);
        }
      }
    };

    checkOfflineInspections();
  }, [isLoggedIn]);

  const syncOffline = async () => {
    const initialResults = {
      total: pendingInspections.length,
      successful: 0,
      failed: 0,
      syncedInspections: []
    };

    try {
      setIsSyncing(true);
      setShowConfirmDialog(false);

      const inspectionResults = await DatabaseService.getAllItems('homeInspectionResults');
      const inspectionImages = await DatabaseService.getAllItems('homeInspectionImages');
      const loggedInUser = getLoggedInUser();

      for (const inspection of pendingInspections) {
        try {
          setCurrentInspection(inspection);

          const preparedInspection = {
            ...inspection,
            homeInspectionID: 0,
            startedByID: loggedInUser.userID,
            assignedInspectorID: loggedInUser.userID,
            assignedByID: loggedInUser.userID,
            lastModifiedDate: new Date().toISOString(),
            creationDate: inspection.timestamp,
            assignedInspectorNote: 'Inspection was created and assigned to user in offline mode.'
          };

          // Remove unnecessary fields
          delete preparedInspection.id;
          delete preparedInspection.timestamp;
          delete preparedInspection.homeInspectionAccessCode;

          // Find related result for inspection (should be 1:1)
          const inspectionResult = inspectionResults.find(
            (result) => result.homeInspectionID === inspection.homeInspectionID
          );

          // Sync inspection
          const inspectionResponse = await api.post("/home-inspections", preparedInspection);

          // Update result with new inspection ID
          if (inspectionResult) {
            inspectionResult.homeInspectionID = inspectionResponse.homeInspectionID;
            await api.post("/home-inspection-results/submit", inspectionResult);
          }

          const inspectionImagesToSync = inspectionImages.filter(img => img.homeInspectionID === inspection.homeInspectionID);
          for (const image of inspectionImagesToSync) {
            const formData = new FormData();
            formData.append("file", image.file);
            formData.append("auditID", image.auditID);
            formData.append("auditQuestionID", image.auditQuestionID);
            formData.append("homeInspectionID", inspectionResponse.homeInspectionID);
      
            try {
              // Send data to the backend
              const response = await api.postFormData("/upload-home-inspection-image", formData);
              if (response.status === 200) {
                await DatabaseService.deleteItem('homeInspectionImages', image.timestamp);
              }
            } catch (error) {
              console.error(`Failed to image to backend sync: ${inspectionResponse.homeInspectionID}`, error);
            }
          }

          // Clean up local database
          await DatabaseService.deleteItem('homeInspections', inspection.homeInspectionID);
          await DatabaseService.deleteItem('homeInspectionResults', inspection.homeInspectionID);

          // Track successful sync
          initialResults.successful++;
          initialResults.syncedInspections.push({
            address: inspection.address,
            id: inspectionResponse.homeInspectionID
          });
        } catch (singleInspectionError) {
          console.error(`Failed to sync inspection ${inspection.address}:`, singleInspectionError);
          initialResults.failed++;
        }
      }

      setSyncResults(initialResults);
    } catch (error) {
      console.error('Sync process failed:', error);
      setSyncError(error);
      initialResults.failed = initialResults.total;
      setSyncResults(initialResults);
    } finally {
      setIsSyncing(false);
      setCurrentInspection(null);
      setPendingInspections([]);
    }
  };

  // Custom Confirm Dialog
  const ConfirmDialog = () => {
    if (!showConfirmDialog) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="confirm-dialog bg-white p-6 rounded-lg shadow-xl">
          <h3 className="text-xl font-bold mb-4">Sync Offline Inspections</h3>
          <p className="mb-4">
            {pendingInspections.length} offline inspection(s) are ready to be synced.
            Would you like to sync them now?
          </p>
          <div className="flex justify-end space-x-2">
            <button
              className="btn btn-standard secondary margin-right-10 float-right px-4 py-2 bg-gray-200 rounded"
              onClick={() => setShowConfirmDialog(false)}
            >
              No
            </button>
            <button
              className="btn btn-danger float-right px-4 py-2 bg-red-500 text-white rounded"
              onClick={() => {
                syncOffline();
                setShowConfirmDialog(false);
              }}
            >
              Sync
            </button>
          </div>
        </div>
      </div>
    );
  };

  const OfflineSyncLoader = () => {
    if (isSyncing) {
      return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="bg-white p-6 rounded-lg shadow-xl text-center w-96">
            <Loader2 className="mx-auto mb-4 h-12 w-12 animate-spin text-blue-500" />
            <h2 className="text-xl font-semibold mb-2">Syncing Offline Data</h2>
            {currentInspection && (
              <p className="text-gray-600 mb-2">
                Syncing inspection: {currentInspection.address || 'Unknown Location'}
              </p>
            )}
            {syncError && (
              <p className="text-red-500 mt-2">
                Sync Error: {syncError.message || 'An unexpected error occurred'}
              </p>
            )}
            <span className='text-gray-800 block mt-4'>
              Please don't close or refresh the browser until the process is complete.
            </span>
          </div>
        </div>
      );
    }

    // Results Dialog
    if (syncResults.total > 0) {
      return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="bg-white p-6 rounded-lg shadow-xl text-center w-96">
            {syncResults.successful === syncResults.total ? (
              <CheckCircle2 className="mx-auto mb-4 h-12 w-12 text-green-500" />
            ) : (
              <AlertTriangle className="mx-auto mb-4 h-12 w-12 text-yellow-500" />
            )}
            <h2 className="text-xl font-semibold mb-2">Sync Complete</h2>
            <div className="mb-4">
              <p>Total Inspections: {syncResults.total}</p>
              <p className="text-green-600">Successful: {syncResults.successful}</p>
              <p className="text-red-600">Failed: {syncResults.failed}</p>
            </div>
            {syncResults.syncedInspections.length > 0 && (
              <div className="mt-4">
                <h3 className="font-semibold mb-2">Synced Inspections:</h3>
                <ul className="max-h-32 overflow-y-auto">
                  {syncResults.syncedInspections.map((inspection) => (
                    <li key={inspection.id} className="text-sm">
                      {inspection.address} (ID: {inspection.id})
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              onClick={() => setSyncResults({ total: 0, successful: 0, failed: 0, syncedInspections: [] })}
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <LoaderProvider>
      <ConfirmDialog />
      <Header isLoggedIn={isLoggedIn} logoutCallBack={logoutCallBack} style={{
        pointerEvents: isSyncing ? 'none' : 'auto',
        opacity: isSyncing ? 0.5 : 1
      }} />
      <main className="fs-container">
        <OfflineSyncLoader />
        <Outlet />
      </main>
      <Footer isLoggedIn={isLoggedIn} />
    </LoaderProvider>
  );
}