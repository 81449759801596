import React from "react";
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import api from "../../../shared/utils/api";
import FormControlInput from "../../../shared/components/FormControlInput";
import { toast } from 'react-toastify';
import { ERROR_MESSAGE_DURATION } from '../../../shared/Constants';

function AuditQuestion() {
    const [question, setQuestion] = useState('');
    const [questionDescription, setQuestionDescription] = useState('');
    const [sortOrder, setSortOrder] = useState(0);
    const [defaultComment, setDefaultComment] = useState('');
    const [questionOptions, setQuestionOptions] = useState([])

    const navigate = useNavigate();

    const params = useParams();

    const { id, moduleID, questionID } = params;

    const fetchCoursePage = () => {
        api.get('/audit-questions/' + questionID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setQuestion(response.question);
                setQuestionDescription(response.questionDescription);
                setSortOrder(response.sortOrder);
                setDefaultComment(response.defaultComment);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    const fetchOptions = () => {
        api.get('/audit-question-options?questionID=' + questionID).then(
            (response) => {
                if (response === null) return;
                if (response === undefined) return;
                setQuestionOptions(response);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            });
    }

    useEffect(() => {
        fetchCoursePage()
        fetchOptions()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        api.post('/audit-questions', {
            auditQuestionID: parseInt(questionID),
            auditModuleID: parseInt(moduleID),
            question: question,
            questionDescription: questionDescription,
            sortOrder: parseInt(sortOrder),
            defaultComment: defaultComment
        }).then(
            () => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    };

    const handleDelete = (e) => {
        e.preventDefault();

        api.delete('/audit-questions/' + questionID).then(
            (response) => {
                navigate(-1);
            },
            (error) => {
                toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
            }
        );
    }

    return (
        <div>
            <form class="form-container" onSubmit={handleSubmit}>
                <h4>{question}</h4>
                <FormControlInput label="Question" type="text" value={question} onChange={(e) => setQuestion(e.target.value)} />
                <FormControlInput label="Question Description" type="text" value={questionDescription} onChange={(e) => setQuestionDescription(e.target.value)} />
                <FormControlInput label="Sort Order" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                <FormControlInput label="Default Comment" type="text" value={defaultComment} onChange={(e) => setDefaultComment(e.target.value)} />
                <div>
                    <h3>Question Options</h3>
                    <ul>
                        {questionOptions.map((option) => (
                            <li key={option.auditQuestionOptionID}>
                                <Link to={"/audits/" + id + "/modules/" + moduleID +
                                    "/questions/" + questionID + "/options/" +
                                    option.auditQuestionOptionID}>
                                    Header: {option.header} - Option: {option.option}
                                    {option.requiresAction && <span style={{color: 'red'}}> (Requires Action)</span>}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <button type="button" className="btn-standard" onClick={() =>
                    navigate("/audits/" + id + "/modules/" + moduleID +
                        "/questions/" + questionID + "/options/add")}>New Question Option</button>
                <br />
                <br />
                <button type="submit" className="btn-standard success">Save</button>
                <button type="button" className="btn-standard danger" onClick={handleDelete}>Delete</button>
            </form>
        </div>
    );
}

export default AuditQuestion;
