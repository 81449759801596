import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import api from "../../../shared/utils/api";
import _ from "lodash";
import HomeOwnerModule from "./HomeOwnerModule";
import { Row, Col } from "react-bootstrap";
import DownloadPdfButton from "../../../shared/components/DownloadPdfButton";
import { MdOutlineArrowBackIos } from "react-icons/md";
import PdfCover from "../../../shared/components/PdfCover";


function HomeOwnerAuditForm() {
  const [audit, setAudit] = useState({});
  const [auditID, setAuditID] = useState(0); // TODO;
  const [accessCode, setAccessCode] = useState(""); // TODO: This will be needed to semi authenticate the user when updating comments
  const [userEmail, setUserEmail] = useState(""); // TODO: This will be needed to semi authenticate the user when updating comments

  const navigate = useNavigate();
  const params = useParams();

  const { id: homeInspectionID } = params;

  let location = useLocation();
  const state = location.state;

  const contentRef = useRef();
  
  const setupAuditFormData = (auditForm, auditResults) => {
    return {
      auditID: auditForm.auditID,
      auditTitle: auditForm.auditTitle,
      auditModules: auditForm.auditModules.map((module) => {
        return {
          moduleTitle: module.moduleTitle,
          auditQuestions: module.auditQuestions?.map((auditQuestion) => {
            return {
              auditQuestionID: auditQuestion.auditQuestionID,
              question: auditQuestion.question,
              auditQuestionOptions: auditQuestion.auditQuestionOptions?.map(
                (answer) => {
                  return {
                    auditQuestionOptionID: answer.auditQuestionOptionID,
                    header: answer.header,
                    option: answer.option,
                    sortOrder: answer.sortOrder,
                    selected: _.includes(
                      auditResults.questionOptionIDs,
                      answer.auditQuestionOptionID,
                    ),
                  };
                },
              ),
              defaultComment: auditQuestion?.defaultComment,
              comment:
                _.find(
                  auditResults.comments,
                  (comment) =>
                    comment.questionID === auditQuestion.auditQuestionID,
                )?.comment || "",
              requiresAction:
                _.find(
                  auditResults.comments,
                  (comment) =>
                    comment.questionID === auditQuestion.auditQuestionID,
                )?.requiresAction || false,
              images:
                _.filter(
                  auditResults.images,
                  (image) => image.questionID === auditQuestion.auditQuestionID,
                ) || [],
            };
          }),
        };
      }),
    };
  };

  const changesRequestedItems = _.flatten(
    audit.auditModules?.map((module) => {
      return module.auditQuestions?.filter(
        (question) => question.requiresAction,
      );
    }),
  ).filter((question) => question !== undefined);

  const handleChangeItemClick = (e) => {
    let a = e.target.querySelector("a");
    if (a) {
      a.click();
    }
  };

  useEffect(() => {
    if (auditID === 0) return;
    if (+homeInspectionID === 0) return;

    const promiseAuditInfo = new Promise((resolve, reject) => {
      api
        .get(
          "/homeowner/audit-form/" +
            auditID +
            "?accessCode=" +
            accessCode +
            "&email=" +
            userEmail,
        )
        .then(
          (response) => {
            if (response) {
              resolve(response);
            }
          },
          (error) => {
            reject(error);
          },
        );
    });

    const promiseAuditResults = new Promise((resolve, reject) => {
      api
        .get(
          "/homeowner/home-inspection-results/" +
            homeInspectionID +
            "?accessCode=" +
            accessCode +
            "&email=" +
            userEmail,
        )
        .then(
          (response) => {
            if (response) {
              resolve(response);
            }
          },
          (error) => {
            reject(error);
          },
        );
    });

    const fetchData = () => {
      Promise.all([promiseAuditInfo, promiseAuditResults]).then((values) => {
        const auditInfo = values[0];
        const auditResults = values[1];

        const tempAudit = setupAuditFormData(auditInfo, auditResults);
        setAudit(tempAudit);
      });
    };

    fetchData();
  }, [auditID, homeInspectionID]);

  useEffect(() => {
    if (state) {
      setAuditID(state.auditID);
      setAccessCode(state.accessCode);
      setUserEmail(state.userEmail);
    }

    if (!state) {
      // TODO: Something went wrong or the use navigated directly to this link and not from
      // the view inspection page so state isn't set. If that's the case then we should redirect
      navigate("/view-home-inspection");
    }
  }, []);

  return (
    <div ref={contentRef}>
      <PdfCover />
      <div>
        <a  className="text-gray-600 pdf-hide" href="#" onClick={(e) => { e.preventDefault(); navigate(-1); }}><MdOutlineArrowBackIos className="inline-block -mt-1 " /> Back to overview</a>
      </div>
      <div className="mb-12 md:mb-0 flex items-center justify-between col-md-8 col-lg-9">        
        <h2 className="mb-6 md:mb-8 mt-2 pdf-audit-title">{audit.auditTitle}</h2>
        <DownloadPdfButton contentRef={contentRef} />
      </div>
      <Row>
        <Col className="col-md-8 col-lg-9">
          {audit.auditModules &&
            [audit.auditModules[audit.auditModules.length - 1], ...audit.auditModules.slice(0, -1)].map((module, index) => (
              <HomeOwnerModule
                key={`mod-${module.auditModuleID}`}
                moduleTitle={`${String.fromCharCode(65 + index)}) ${module.moduleTitle}`}
                moduleQuestions={module.auditQuestions}
              />
            ))}
        </Col>

        {changesRequestedItems.length > 0 && (
          <div className="col-md-3 no-print">
            <div id="inspection-minimap">
              <h4>Changes Requested</h4>
              {changesRequestedItems.map((question) => (
                <div className="change-item" onClick={handleChangeItemClick}>
                  <a href={"#" + question.question}>{question.question}</a>
                </div>
              ))}
            </div>
          </div>
        )}
      </Row>
    </div>
  );
}

export default HomeOwnerAuditForm;
