import React, { useEffect, useState } from 'react';
import loadingManager from '../../shared/utils/loadingManager';
import Loader from './Loader';

const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = loadingManager.subscribe(setIsLoading);
    return () => unsubscribe();
  }, []);

  return (
    <>
      {children}
      {isLoading && <Loader />}
    </>
  );
};

export default LoaderProvider;