import React from 'react'

export default function PdfCover() {
  return (
    <div className="pdf-cover hidden">
      <div 
        style={{ backgroundColor: '#212a34' }}
        className='h-screen w-[100%] flex flex-col items-center gap-12 justify-center'
      >
        <img 
          src="/firesmart-pdf-cover.png"
          className='w-[400px]'
        />
        <div className='text-4xl font-bold text-white py-4 border-y-2'>
          Wildfire Mitigation Assessment
        </div>
      </div>
    </div>
  )
}
