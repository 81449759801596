import React, {useState, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getStoredAuthToken } from './authToken';

export default function ProtectedRoute(props) {
    const isAuthenticated = getStoredAuthToken() !== undefined;
    const location = useLocation();
    const [isOffline, setIsOffline] = useState(!navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOffline(false);
        const handleOffline = () => setIsOffline(true);
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, []);

    return (
        isOffline || isAuthenticated ? props.children : <Navigate to={{ pathname: '/home', state: { from: location } }} />
    );
}
