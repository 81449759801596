import axios from 'axios';
import { objectToQueryString } from './url';
import { getStoredAuthToken, removeStoredAuthToken, isTokenExpired, storeAuthToken } from './authToken';
import Cookies from 'js-cookie';
import loadingManager from './loadingManager';

const defaults = {
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  headers: (dataType) => ({
    'Content-Type': dataType === 'form-data' ? 'multipart/form-data' : 'application/json',
    Authorization: getStoredAuthToken() ? `Basic ${getStoredAuthToken()}` : undefined, //TODO: This will need to be Bearer instead of basic eventually
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'The FireSmart app is having trouble. Please try again later.',
    status: 503,
    data: {},
  },
};

const axiosInstance = axios.create({
  baseURL: defaults.baseURL,
  headers: defaults.headers('application/json'),
});

const refreshAuthToken = async () => {
  try {
    loadingManager.show();
    const response = await axiosInstance.get('/token-refresh');
    storeAuthToken(response.data.token);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data', error);
    throw error;
  } finally {
    loadingManager.hide();
  }
};

const api = async (method, dataType, url, variables) => {
  if (isTokenExpired() && Cookies.get('authToken') && localStorage.getItem('tokenStatus') !== 'refreshing') {
    localStorage.setItem('tokenStatus', 'refreshing');
    await refreshAuthToken();
  }

  return new Promise((resolve, reject) => {
    loadingManager.show();
    
    axiosInstance({
      url: url,
      method,
      headers: defaults.headers(dataType),
      params: method === 'get' ? variables : undefined,
      data: method !== 'get' ? variables : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      response => {
        if (response === undefined || response === null) return;
        loadingManager.hide();
        resolve(response.data);
      },
      error => {
        loadingManager.hide();
        if (error.response) {
          switch (error.response.status) {
            case 401:
              removeStoredAuthToken();
              window.location.href = '/login?redirectURL=' + window.location.pathname;
              break;
            case 403:
              // TODO: We'll probably want some better handling for this. Perhaps even just a toast that says forbidden.
              window.location.href = '/';
              break;
            default:
              reject(error.response.data);
              break;
          }
        } else {
          reject(defaults.error);
        }
      },
    );
  });
};

export default {
  get: (...args) => api('get', undefined, ...args),
  post: (...args) => api('post', undefined, ...args),
  postFormData: (...args) => api('post', 'form-data', ...args),
  put: (...args) => api('put', undefined, ...args),
  patch: (...args) => api('patch', undefined, ...args),
  delete: (...args) => api('delete', undefined, ...args),
};
