import { addItem } from './../shared/idpHelper';
import api from './../shared/utils/api';

const STORE_NAME = "territories";

export async function fetchAndSaveTerritories() {
  const response = await api.get("/territories-for-public");
  for (const territory of response) {
    await addItem(STORE_NAME, territory);
  }
}