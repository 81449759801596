import React from "react";
import { useLocation } from "react-router-dom";


export default function Footer({ isLoggedIn }) {
  const supportLink = isLoggedIn ? "/assessor-support" : "/homeowner-support";
  const location = useLocation();
  const isHomepage = location.pathname === '/' || location.pathname === '/home';
  return (
    <footer className="fs-container pb-8 pt-10 text-base ">    
      {!isHomepage && (
        <img src="/wmp-logo-dark.png" className="w-[180px] mb-4" alt="WMP Logo"/>
      )}
      <div className="text-left flex justify-between"> 
        <p>Having issues with the platform? Please visit our <a className="underline" href={supportLink}>support page</a>.</p>
        <p>
          <a className="underline" href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>
    </footer>
  );
}
