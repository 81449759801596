import "./Annotator.css";
import * as Dialog from "@radix-ui/react-dialog";
import { useEffect, useRef, useState } from "react";
import {
  Brush,
  PlusCircle,
  Square,
  Circle,
  Triangle,
  Undo,
  Redo,
  X,
  Upload,
} from "lucide-react";

function UploadImageOverlay({ onImageUpload }) {
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [isMobile] = useState(() => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Pixel/i.test(
        navigator.userAgent
      ) ||
      /Android.*Mobile/.test(navigator.userAgent) ||
      typeof window.orientation !== "undefined"
    );
  });

  const checkCameraPermissions = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "granted") {
        return true; // No need to ask for permission again
      }

      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (err) {
      if (err.name === "NotAllowedError" || err.name === "AbortError") {
        console.warn("User denied camera access or canceled request");
        return false;
      }
      console.warn("Camera permission denied:", err);
      return false;
    }
  };

  const handleTakePhoto = async (e) => {
    const hasPermission = await checkCameraPermissions();
    if (!hasPermission) {
      e.preventDefault();
      setShowPermissionPopup(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer?.files?.[0];
    if (file && file.type.startsWith("image/")) {
      onImageUpload(file);
    }
  };

  return (
    <div className="absolute inset-0 z-50 bg-white flex items-center justify-center p-4">
      {showPermissionPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[60]">
          <div className="bg-white p-6 rounded-lg max-w-sm mx-4">
            <h3 className="text-lg font-semibold mb-2">
              Camera Access Required
            </h3>
            <p className="text-gray-600 mb-4">
              Please enable camera access in your browser settings to use this
              feature.
            </p>
            <button
              onClick={() => setShowPermissionPopup(false)}
              className="bg-[#efca00] hover:bg-[#efc300] text-black px-4 py-2 rounded font-bold"
            >
              OK
            </button>
          </div>
        </div>
      )}
      <div
        className="py-20 border-2 border-dashed border-gray-300 rounded-lg w-full max-w-2xl flex flex-col items-center justify-center gap-4 hover:border-[#efca00] transition-colors"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Upload size={48} className="text-gray-400" />
        <p className="text-gray-600 hidden lg:block">
          Drag and drop an image here, or
        </p>
        <div className="flex flex-col sm:flex-row gap-2">
          {isMobile && (
            <label
              className="xl:hidden bg-[#efca00] hover:bg-[#efc300] text-black px-4 h-10 flex items-center justify-center rounded cursor-pointer transition-colors"
              onClick={handleTakePhoto}
            >
              <input
                type="file"
                accept="image/*,android/force-camera-workaround"
                capture="environment"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) onImageUpload(file);
                }}
                className="hidden"
              />
              <span className="translate-y-0.5 font-bold">Take Photo</span>
            </label>
          )}
          <label className="hidden bg-[#efca00] hover:bg-[#efc300] text-black px-4 h-10 lg:flex items-center justify-center rounded cursor-pointer transition-colors">
            <input
              type="file"
              accept="image/*,android/force-camera-workaround"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) onImageUpload(file);
              }}
              className="hidden"
            />
            <span className="translate-y-0.5 font-bold">Choose Image</span>
          </label>
          <div className="lg:hidden">
            <label className="bg-white border hover:bg-[#efc300] text-black px-4 h-10 flex items-center justify-center rounded cursor-pointer transition-colors">
              <input
                type="file"
                accept="image/*,android/force-camera-workaround"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) onImageUpload(file);
                }}
                className="hidden"
              />
              <span className="translate-y-0.5 font-bold">Choose Image</span>
            </label>
          </div>
        </div>
        <p className="text-sm text-gray-500">Maximum file size: 25MB</p>
      </div>
    </div>
  );
}

export default function Annotator({ handleFileSubmit }) {
  const MAX_SIZE_IN_KB = 150;
  const [open, setOpen] = useState(false); 

  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedTool, setSelectedTool] = useState("brush");
  const [brushSize, setBrushSize] = useState(5);
  const [selectedColor, setSelectedColor] = useState("#f00");

  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  const drawingHistory = useRef([]);
  const redoHistory = useRef([]);
  const currentStep = useRef(0);
  const prevMousePoint = useRef({ x: 0, y: 0 });
  const canvasSnapshot = useRef(null);

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    if (!open) return;

    const timer = setTimeout(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctxRef.current = ctx;

      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();

      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;

      ctx.resetTransform();
      ctx.scale(dpr, dpr);

      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, rect.width, rect.height);

      const savedDrawing = localStorage.getItem("savedDrawing");
      if (savedDrawing) {
        const image = new Image();
        image.src = savedDrawing;
        image.onload = () => {
          ctx.drawImage(image, 0, 0, rect.width, rect.height);
        };
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [open]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctxRef.current = ctx;

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    document.documentElement.style.setProperty(
      "--doc-height",
      `${window.innerHeight}px`
    );
    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    ctx.scale(dpr, dpr);
  };

  const currMousePoint = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };
    const rect = canvas.getBoundingClientRect();
    return {
      x: ("touches" in e ? e.touches[0].clientX : e.clientX) - rect.left,
      y: ("touches" in e ? e.touches[0].clientY : e.clientY) - rect.top,
    };
  };

  const drawRectangle = (position) => {
    const ctx = ctxRef.current;
    if (!ctx) return;

    ctx.beginPath();
    const width = position.x - prevMousePoint.current.x;
    const height = position.y - prevMousePoint.current.y;
    ctx.rect(prevMousePoint.current.x, prevMousePoint.current.y, width, height);
    fillColor ? ctx.fill() : ctx.stroke();
    ctx.closePath();
  };

  const drawCircle = (position) => {
    const ctx = ctxRef.current;
    if (!ctx) return;

    ctx.beginPath();
    const radius = Math.sqrt(
      Math.pow(prevMousePoint.current.x - position.x, 2) +
        Math.pow(prevMousePoint.current.y - position.y, 2)
    );
    ctx.arc(
      prevMousePoint.current.x,
      prevMousePoint.current.y,
      radius,
      0,
      2 * Math.PI
    );
    fillColor ? ctx.fill() : ctx.stroke();
  };

  const drawTriangle = (position) => {
    const ctx = ctxRef.current;
    if (!ctx) return;

    ctx.beginPath();
    ctx.moveTo(prevMousePoint.current.x, prevMousePoint.current.y);
    ctx.lineTo(position.x, position.y);
    ctx.lineTo(prevMousePoint.current.x * 2 - position.x, position.y);
    ctx.closePath();
    fillColor ? ctx.fill() : ctx.stroke();
  };

  const drawStart = (e) => {
    e.preventDefault();
    setIsDrawing(true);

    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx) return;

    ctx.beginPath();
    ctx.lineCap = "round";
    prevMousePoint.current = currMousePoint(e);
    ctx.lineWidth = brushSize;
    ctx.strokeStyle = selectedTool === "eraser" ? "#fff" : selectedColor;
    ctx.fillStyle = selectedColor;
    canvasSnapshot.current = ctx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );
  };

  const drawing = (e) => {
    if (!isDrawing) return;
    e.preventDefault();

    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx || !canvasSnapshot.current) return;

    const position = currMousePoint(e);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(canvasSnapshot.current, 0, 0);

    if (selectedTool === "brush" || selectedTool === "eraser") {
      ctx.strokeStyle = selectedTool === "eraser" ? "#fff" : selectedColor;
      ctx.lineTo(position.x, position.y);
      ctx.stroke();
    } else if (selectedTool === "rect") {
      drawRectangle(position);
    } else if (selectedTool === "circle") {
      drawCircle(position);
    } else if (selectedTool === "triangle") {
      drawTriangle(position);
    }
  };

  const drawStop = () => {
    if (!isDrawing) return;
    setIsDrawing(false);
    saveDrawingState();
  };

  const saveDrawingState = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (currentStep.current < drawingHistory.current.length - 1) {
      drawingHistory.current = drawingHistory.current.slice(
        0,
        currentStep.current + 1
      );
    }
    currentStep.current++;
    drawingHistory.current.push(canvas.toDataURL());
    redoHistory.current = [];
    saveDrawingToLocalstorage();
  };

  const saveDrawingToLocalstorage = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    try {
      const canvasDrawing = canvas.toDataURL("image/jpeg", 0.5);
      localStorage.setItem("savedDrawing", canvasDrawing);
    } catch (e) {
      console.warn("Failed to save drawing to localStorage:", e);
    }
  };

  const handleToolClick = (toolId) => {
    setSelectedTool(toolId);
  };

  const handleBrushSize = (e) => {
    setBrushSize(parseInt(e.target.value));
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const handleUndoRedo = (action) => {
    if (action === "undo" && currentStep.current > 0) {
      currentStep.current--;
      redoHistory.current.push(drawingHistory.current[currentStep.current + 1]);
    } else if (action === "redo" && redoHistory.current.length > 0) {
      currentStep.current++;
      drawingHistory.current.push(redoHistory.current.pop());
    } else {
      return;
    }

    const image = new Image();
    image.src = drawingHistory.current[currentStep.current];
    image.onload = () => {
      const canvas = canvasRef.current;
      const ctx = ctxRef.current;
      if (!canvas || !ctx) return;

      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();

      ctx.resetTransform();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      ctx.scale(dpr, dpr);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, rect.width, rect.height);
      saveDrawingToLocalstorage();
    };
  };

  const handleClearCanvas = () => {
    if (window.confirm("Are you sure you want to clear the canvas?")) {
      const canvas = canvasRef.current;
      const ctx = ctxRef.current;
      if (!canvas || !ctx) return;

      localStorage.removeItem("savedDrawing");
      drawingHistory.current = [];
      redoHistory.current = [];
      currentStep.current = 0;

      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      if (isImageUploaded) {
        const originalImage = localStorage.getItem("originalImage");
        if (originalImage) {
          const image = new Image();
          image.src = originalImage;
          image.onload = () => {
            const rect = canvas.getBoundingClientRect();
            const scale = Math.min(
              rect.width / image.width,
              rect.height / image.height
            );
            const x = (rect.width - image.width * scale) / 2;
            const y = (rect.height - image.height * scale) / 2;

            ctx.drawImage(
              image,
              x,
              y,
              image.width * scale,
              image.height * scale
            );
            saveDrawingState();
          };
        }
      }
    }
  };

  const [fillColor, setFillColor] = useState(false);

  const handleImageUpload = (file) => {
    if (!file) return;

    const maxSize = 25 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("Image size must be less than 25MB");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        if (!canvas || !ctx) return;

        const dpr = window.devicePixelRatio || 1;
        const rect = canvas.getBoundingClientRect();
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr;
        ctx.scale(dpr, dpr);

        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const scale = Math.min(
          rect.width / image.width,
          rect.height / image.height
        );
        const x = (rect.width - image.width * scale) / 2;
        const y = (rect.height - image.height * scale) / 2;

        ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
        const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.4); // Adjust quality as needed
        localStorage.setItem("originalImage", compressedDataUrl);

        saveDrawingState();
        setIsImageUploaded(true);
      };
      image.src = event.target?.result;
    };
    reader.readAsDataURL(file);
    setIsImageUploaded(true);
  };

  function handleSave() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const canvas = canvasRef.current;
    if (!canvas) return;
  
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    const compressImage = (quality) => {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (!blob) return resolve(null);
          
          const sizeInKB = blob.size / 1024; // Convert bytes to KB
          console.log(`Current size: ${sizeInKB.toFixed(2)}KB, Quality: ${quality}`);
  
          if (sizeInKB > MAX_SIZE_IN_KB && quality > 0.1) {
            resolve(compressImage(quality - 0.05)); // Recursively reduce quality
          } else {
            resolve(blob);
          }
        }, "image/jpeg", quality);
      });
    };
  
    compressImage(0.8).then((compressedBlob) => {
      if (!compressedBlob) {
        setIsSubmitting(false);
        console.error("Compression failed.");
        setIsSubmitting(false);
        return;
      }
  
      const file = new File([compressedBlob], "compressed-image.jpg", { type: "image/jpeg" });
      handleFileSubmit(file);
  
      const ctx = ctxRef.current;
      if (ctx) {
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
  
      localStorage.removeItem('savedDrawing');
      drawingHistory.current = [];
      redoHistory.current = [];
      currentStep.current = 0;
      setIsImageUploaded(false);
      handleToolClick('brush');
      setSelectedColor('#f00');
  
      // Close the modal only after compression is fully complete
      setOpen(false);
      setIsSubmitting(false);
    });
  }

  const resetCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx) return;

    localStorage.removeItem("savedDrawing");
    localStorage.removeItem("originalImage");

    drawingHistory.current = [];
    redoHistory.current = [];
    currentStep.current = 0;
    setIsImageUploaded(false);
    handleToolClick("brush");
    setSelectedColor("#f00");

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          resetCanvas();
        }
        setOpen(isOpen);
      }}
    >
      <Dialog.Trigger>
        <button className="flex flex-col items-center justify-center gap-1 w-20 h-20 border rounded">
          <PlusCircle size={16} className="mt-1" />{" "}
          <span className="mt-[1px] text-sm">Add</span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="z-[200] fixed inset-0 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="z-[250] fixed inset-0 h-full w-full flex items-center justify-center rounded-xl bg-[#ffffff] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          {!isImageUploaded && (
            <UploadImageOverlay onImageUpload={handleImageUpload} />
          )}
          <div className="annotator_container relative">
            <section className="annotator_tools-board ">
              <div className="annotator_all-tools annotator_shapes-tool">
                <div className="annotator_row">
                  <label
                    className="annotator_title"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Tools
                  </label>
                  <div className="annotator_options flex gap-2">
                    <button
                      className={`annotator_option annotator_tool border p-1.5 rounded ${selectedTool === "brush" ? "bg-[#efca00] border-[#efc300] text-black" : ""}`}
                      onClick={() => handleToolClick("brush")}
                    >
                      <Brush size={20} />
                    </button>
                    <button
                      className={`annotator_option annotator_tool border p-1.5 rounded ${selectedTool === "rect" ? "bg-[#efca00] border-[#efc300] text-black" : ""}`}
                      onClick={() => handleToolClick("rect")}
                    >
                      <Square size={20} />
                    </button>
                    <button
                      className={`annotator_option annotator_tool border p-1.5 rounded ${selectedTool === "circle" ? "bg-[#efca00] border-[#efc300] text-black" : ""}`}
                      onClick={() => handleToolClick("circle")}
                    >
                      <Circle size={20} />
                    </button>
                    <button
                      className={`annotator_option annotator_tool border p-1.5 rounded ${selectedTool === "triangle" ? "bg-[#efca00] border-[#efc300] text-black" : ""}`}
                      onClick={() => handleToolClick("triangle")}
                    >
                      <Triangle size={20} />
                    </button>
                  </div>
                </div>
                <div className="annotator_row annotator_options-tool">
                  <label
                    className="annotator_title"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Thickness
                  </label>
                  <ul className="annotator_options">
                    <li className="annotator_option">
                      <input
                        type="range"
                        id="brush-size-slider"
                        min="1"
                        max="100"
                        value={brushSize}
                        onChange={handleBrushSize}
                      />
                    </li>
                  </ul>
                </div>
                <div className="annotator_joined-tools">
                  <div className="annotator_row annotator_colors-tool">
                    <label
                      className="annotator_title"
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      Colors
                    </label>
                    <ul className="annotator_options annotator_colors">
                      {["#f00", "#0f0", "#00f", "#fff"].map((color) => (
                        <button
                          key={color}
                          className={`annotator_option border-2  ${selectedColor === color ? "border-black" : ""}`}
                          style={{
                            backgroundColor: color,
                          }}
                          onClick={() => handleColorClick(color)}
                        />
                      ))}
                    </ul>
                  </div>
                  <div className="annotator_row annotator_actions-tool">
                    <label
                      className="annotator_title"
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      Actions
                    </label>
                    <ul className="annotator_options annotator_horizontal flex items-center gap-4">
                      <button
                        className="annotator_option"
                        onClick={() => handleUndoRedo("undo")}
                      >
                        <Undo />
                        <span className="annotator_name">Undo</span>
                      </button>
                      <button
                        className="annotator_option"
                        onClick={() => handleUndoRedo("redo")}
                      >
                        <Redo />
                        <span className="annotator_name">Redo</span>
                      </button>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="annotator_row annotator_buttons space-y-3">
                <button
                  className="annotator_clear-canvas"
                  onClick={handleClearCanvas}
                >
                  <span className="annotator_name text-lg translate-y-0.5">
                    Clear Canvas
                  </span>
                </button>
                {isImageUploaded && (
                  <button
                    className="annotator_save-img !bg-[#efca00] hover:!bg-[#efc300] !border-[#efca00] !text-black"
                    onClick={handleSave}
                  >
                    <span className="annotator_name text-lg translate-y-0.5">
                      Save Image
                    </span>
                  </button>
                )}
              </div>
            </section>
            <section className="annotator_drawing-board h-full w-full">
              <canvas
                ref={canvasRef}
                onMouseDown={drawStart}
                onMouseMove={drawing}
                onMouseUp={drawStop}
                onMouseLeave={drawStop}
                onTouchStart={drawStart}
                onTouchMove={drawing}
                onTouchEnd={drawStop}
                className="h-full w-full"
              />
            </section>
          </div>
          <Dialog.Close asChild>
            <button
              className="z-50 absolute top-3.5 right-3 h-7 w-[2.2rem] flex items-center justify-center outline-none"
              aria-label="Close"
              onClick={() => {
                resetCanvas();
                setOpen(false);
              }}
            >
              <X
                size={24}
                className="hover:rotate-90 transition duration-300"
              />
              <span className="sr-only">Close</span>
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
