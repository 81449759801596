import { openDB } from 'idb';

const DB_CONFIG = {
  name: "fsOfflineDb",
  version: 1,
  stores: {
    territories: { keyPath: "id" },
    homeInspectionImages: { keyPath: "timestamp" },
    auditForms: { keyPath: "auditID" },
    homeInspectionResults: { keyPath: "homeInspectionID" },
    homeInspections: { keyPath: "homeInspectionID" }
  }
};

/**
 * Database service class to handle all IndexedDB operations
 */
class DatabaseService {
  /**
   * Opens a connection to the database
   * @param {string} [storeName] - Optional store name for single store operations
   * @returns {Promise<IDBDatabase>}
   */
  static async openDatabase(storeName = null) {
    try {
      if (storeName) {
        return openDB(DB_CONFIG.name, DB_CONFIG.version, {
          upgrade(db) {
            if (!db.objectStoreNames.contains(storeName)) {
              db.createObjectStore(storeName, DB_CONFIG.stores[storeName] || { keyPath: "id" });
            }
          },
        });
      }

      return openDB(DB_CONFIG.name, DB_CONFIG.version, {
        upgrade(db, oldVersion, newVersion, transaction) {
          // Create all stores if they don't exist
          Object.entries(DB_CONFIG.stores).forEach(([name, config]) => {
            if (!db.objectStoreNames.contains(name)) {
              db.createObjectStore(name, config);
            }
          });
        },
      });
    } catch (error) {
      console.error('Failed to open database:', error);
      throw new Error('Database connection failed');
    }
  }

  /**
   * Initialize the database and load initial data
   */
  static async initialize() {
    try {
      console.log('Initializing offline database...');
      await this.openDatabase();
      console.log('Database initialization complete');
    } catch (error) {
      console.error('Database initialization failed:', error);
      throw error;
    }
  }

  /**
   * Generic method to perform a database transaction
   * @param {string} storeName - Name of the store
   * @param {string} mode - Transaction mode ('readonly' or 'readwrite')
   * @param {Function} operation - Operation to perform on the store
   * @returns {Promise<any>}
   */
  static async performTransaction(storeName, mode, operation) {
    try {
      const db = await this.openDatabase(storeName);
      const tx = db.transaction(storeName, mode);
      const store = tx.objectStore(storeName);

      const result = await operation(store);
      await tx.done;

      return result;
    } catch (error) {
      console.error(`Transaction failed for ${storeName}:`, error);
      throw error;
    }
  }

  /**
   * Add or update an item in a store
   * @param {string} storeName - Name of the store
   * @param {Object} item - Item to add or update
   * @returns {Promise<void>}
   */
  static async saveItem(storeName, item) {
    return this.performTransaction(storeName, 'readwrite', store =>
      store.put(item)
    );
  }

  /**
   * Add or update multiple items in a store
   * @param {string} storeName - Name of the store
   * @param {Array<Object>} items - Items to add or update
   * @returns {Promise<void>}
   */
  static async saveItems(storeName, items) {
    return this.performTransaction(storeName, 'readwrite', async store => {
      for (const item of items) {
        await store.put(item);
      }
    });
  }

  /**
   * Get an item from a store by ID
   * @param {string} storeName - Name of the store
   * @param {string|number} id - Item ID
   * @returns {Promise<Object|undefined>}
   */
  static async getItem(storeName, id) {
    return this.performTransaction(storeName, 'readonly', store =>
      store.get(id)
    );
  }

  /**
   * Get all items from a store
   * @param {string} storeName - Name of the store
   * @returns {Promise<Array>}
   */
  static async getAllItems(storeName) {
    return this.performTransaction(storeName, 'readonly', store =>
      store.getAll()
    );
  }

  /**
   * Delete an item from a store
   * @param {string} storeName - Name of the store
   * @param {string|number} id - Item ID
   * @returns {Promise<void>}
   */
  static async deleteItem(storeName, id) {
    return this.performTransaction(storeName, 'readwrite', store =>
      store.delete(id)
    );
  }

  /**
   * Clear all items from a store
   * @param {string} storeName - Name of the store
   * @returns {Promise<void>}
   */
  static async clearStore(storeName) {
    return this.performTransaction(storeName, 'readwrite', store =>
      store.clear()
    );
  }

  /**
   * Check if an item exists in a store
   * @param {string} storeName - Name of the store
   * @param {string|number} id - Item ID
   * @returns {Promise<boolean>}
   */
  static async hasItem(storeName, id) {
    const item = await this.getItem(storeName, id);
    return item !== undefined;
  }

  /**
   * Get the count of items in a store
   * @param {string} storeName - Name of the store
   * @returns {Promise<number>}
   */
  static async getCount(storeName) {
    console.log('getcount');
    return this.performTransaction(storeName, 'readonly', store =>
      store.count()
    );
  }
}

// Export individual functions for backward compatibility
export const setupOfflineDb = () => DatabaseService.initialize();
export const addItem = (storeName, item) => DatabaseService.saveItem(storeName, item);
export const getItem = (storeName, id) => DatabaseService.getItem(storeName, id);
export const getAllItems = (storeName) => DatabaseService.getAllItems(storeName);
export const deleteItem = (storeName, id) => DatabaseService.deleteItem(storeName, id);
export const openDBHelper = (storeName) => DatabaseService.openDatabase(storeName);

// Export the full service for more advanced usage
export default DatabaseService;