let loadingCount = 0;
const subscribers = new Set();

const loadingManager = {
  show: () => {
    loadingCount++;
    notifySubscribers();
  },
  
  hide: () => {
    loadingCount = Math.max(0, loadingCount - 1);
    notifySubscribers();
  },
  
  isLoading: () => loadingCount > 0,
  
  subscribe: (callback) => {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  },
};

const notifySubscribers = () => {
  subscribers.forEach(callback => callback(loadingManager.isLoading()));
};

export default loadingManager;