import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js', { type: 'module' })
      .then((registration) => {
        registration.update();
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  }
}

function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister()
          .then(() => {
            console.log('Service Worker unregistered.');
          })
          .catch((error) => {
            console.error('Service Worker unregistration failed:', error);
          });
      });
    });
  }
}

async function enablePersistentStorage() {
  if ('storage' in navigator && 'persist' in navigator.storage) {
    const isPersisted = await navigator.storage.persist();
    if (isPersisted) {
      console.log("Storage is now persistent. Data will not be cleared except by user action.");
    } else {
      console.log("Failed to make storage persistent. Data may be cleared under low storage conditions.");
    }
  } else {
    console.log("Persistent storage is not supported on this browser.");
  }
}

window.addEventListener('load', () => {
  //enablePersistentStorage();
  //registerServiceWorker();
  // if (navigator.onLine) {
  //   console.log('Application is online. Unregistering Service Worker...');
  //   unregisterServiceWorker();
  // } else {
  //   console.log('Application is offline. Registering Service Worker...');
  //   registerServiceWorker();
  // }

  // // Listen for changes in connectivity
  // window.addEventListener('online', () => {
  //   console.log('Application went online. Unregistering Service Worker...');
  //   unregisterServiceWorker();
  // });

  // window.addEventListener('offline', () => {
  //   console.log('Application went offline. Registering Service Worker...');
  //   registerServiceWorker();
  // });
});

// // Ensure the Service Worker works when accessed offline
// if (!navigator.onLine) {
//   console.log('User started the app offline. Registering Service Worker...');
//   registerServiceWorker();
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
  