import react from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoMdCloseCircle } from "react-icons/io";

function Image({ key, imageURL, removeImageCallback, readOnly }) {
  const removeImage = (e) => {
    removeImageCallback();
  };

  const showImageFullScreen = (e) => {
    if (e.target.tagName !== "DIV" && e.target.tagName !== "IMG") return;
    var fullpage = document.getElementById("fullpage");
    fullpage.style.display = "block";
    fullpage.style.backgroundImage = "url(" + imageURL + ")";
  };

  return (
    <div className="relative" key={key}>
      <div
        className="audit-image-preview audit-image-full cursor-pointer"
        onClick={showImageFullScreen}
      >
        <img src={imageURL} alt="Audit Image" width={150} height={150} />
        {!readOnly && (
          <IoMdCloseCircle
            className="h-7 w-7 absolute -top-2.5 -right-2.5 cursor-pointer hover:text-gray-800 transition-all duration-300 bg-gray-100 hover rounded-full pdf-hide"
            onClick={removeImage}
          />
        )}
      </div>
    </div>
  );
}

export default Image;
