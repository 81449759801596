import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserProfileHeader from "./UserProfileHeader";
import NavLinkAuth from "./NavLinkAuth";
import { getLoggedInUser, isUserAdmin, isUserAdminOnly } from "../shared/utils/authToken";
import { GoHome } from "react-icons/go";
import { LiaClipboardCheckSolid } from "react-icons/lia";
import { IoPeopleOutline, IoMapOutline, IoCloudOfflineOutline } from "react-icons/io5";
import { LiaClipboardListSolid } from "react-icons/lia";
import { IoEyeOutline } from "react-icons/io5";
import { HiMenu, HiX } from "react-icons/hi";

export default function Header({ isLoggedIn, logoutCallBack, style }) {
  const [user, setUser] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const navigate = useNavigate();

  const canViewAssessments = () => {
    return (
      user !== null && (user.canAssess || user.userRoleID === 1 || user.userRoleID === 2)
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      const user = getLoggedInUser();
      setUser(user);
    }

    if (!isLoggedIn) {
      setUser({});
    }

    // Setup offline/online event listeners
    const handleOnline = () => {
      setIsOffline(false);
    };
    const handleOffline = () => {
      setIsOffline(true);
      logoutCallBack();
      navigate('/home');
      window.location.reload();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isLoggedIn, logoutCallBack, navigate]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="header no-print border-b border-border bg-offWhite" style={style}>
      <div className="fs-container">
        <nav className="flex items-center justify-between flex-wrap py-3">
          {/* Logo with Offline Indicator */}
          <div className="flex items-center flex-shrink-0 mr-3">
            <NavLink to="/" className="relative">
              <img src="/logo-black.svg" alt="FireSmart" className="w-[150px] xl:w-[200px]" />
            </NavLink>
            {isOffline && (
              <div className="flex items-center bg-red-100 text-red-800 px-2 py-2 rounded-full text-xs ml-2 mt-3">
                <IoCloudOfflineOutline className="mr-1" />
                Offline
              </div>
            )}
          </div>
          {/* Hamburger Menu Button */}
          <div className="block lg:hidden">
            <button
              className="text-gray-700 focus:outline-none"
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <HiX className="h-6 w-6" />
              ) : (
                <HiMenu className="h-6 w-6" />
              )}
            </button>
          </div>
          {/* Navigation Links */}
          <div
            className={`w-full lg:w-auto mt-3 lg:!mt-0  ${isMenuOpen ? "block" : "hidden"
              } lg:block lg:flex lg:items-center`}
          >
            <ul className="o lg:flex md:space-x-1 xl:space-x-3 mb-0 pl-0 mt-0">
              <li>
                <NavLinkAuth
                  to="/"
                  authorized={isLoggedIn}
                  onClick={handleLinkClick}
                >
                  <GoHome className="mr-2 h-5 w-5 -mt-0.5 inline-block" />
                  Dashboard
                </NavLinkAuth>
              </li>
              {canViewAssessments() && (
                <li>
                  <NavLinkAuth
                    to="/home-inspections"
                    authorized={isLoggedIn}
                    onClick={handleLinkClick}
                  >
                    <LiaClipboardCheckSolid className="mr-2 h-5 w-5 -mt-0.5 inline-block" />
                    Assessments
                  </NavLinkAuth>
                </li>
              )}
              {isUserAdmin() && (
                <li>
                  <NavLinkAuth
                    to="/Users"
                    authorized={isLoggedIn}
                    onClick={handleLinkClick}
                  >
                    <IoPeopleOutline className="mr-2 h-5 w-5 inline-block -mt-0.5" />
                    Users
                  </NavLinkAuth>
                </li>
              )}
              {isUserAdminOnly() && (
                <li>
                  <NavLinkAuth
                    to="/agencies"
                    authorized={isLoggedIn}
                    onClick={handleLinkClick}
                  >
                    <IoMapOutline className="mr-2 h-5 w-5 inline-block -mt-0.5" />
                    Agencies
                  </NavLinkAuth>
                </li>
              )}
              {!isLoggedIn && (
                <>
                  <li>
                    <NavLink
                      to="/request-home-inspection"
                      className="flex items-center px-3 py-2 text-black hover:bg-gray-200 rounded-md"
                      onClick={handleLinkClick}
                    >
                      <LiaClipboardListSolid className="mr-2 h-5 w-5 -mt-1 inline-block" />
                      Request Assessment
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={isOffline ? "/home-inspections-offline" : "/view-home-inspection"}
                      className="flex items-center px-3 py-2 text-black hover:bg-gray-200 rounded-md"
                      onClick={handleLinkClick}
                    >
                      {isOffline ? (
                        <IoCloudOfflineOutline className="mr-2 h-5 w-5 -mt-1 inline-block" />
                      ) : (
                        <IoEyeOutline className="mr-2 h-5 w-5 -mt-1 inline-block" />
                      )}
                      {isOffline ? "Offline Assessments" : "View Assessment"}
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <div className="block lg:hidden">
              <UserProfileHeader
                isLoggedIn={isLoggedIn}
                logoutCallBack={logoutCallBack}
                user={user}
                onClick={handleLinkClick}
                hidden={isOffline}
              />
            </div>
          </div>
          {/* User Profile */}
          <div className="hidden lg:block">
            <UserProfileHeader
              isLoggedIn={isLoggedIn}
              logoutCallBack={logoutCallBack}
              user={user}
              hidden={isOffline}
            />
          </div>
        </nav>
      </div>
    </div>
  );
}