import { useReactToPrint } from "react-to-print";
import { AiOutlineDownload } from "react-icons/ai";
import loadingManager from "../utils/loadingManager";

export default function DownloadPdfButton({ contentRef }) {

  const reactToPrintFn = useReactToPrint({
    contentRef,
    onBeforeGetContent: () => {
      loadingManager.show();
    },
    onAfterPrint: () => {
      loadingManager.hide();
    },
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }

      @media print {

        html, body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
          color-adjust: exact;
        }

        .pdf-images__container {
          display: grid!important;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 10px;
        }

        .page-break {
          display: block;
          page-break-inside: avoid;
          break-inside: avoid;
          margin-top: 2rem;
        }

        .pdf-cover {
          display: block!important;
        }

        h2 {
          font-size: 20px!important;
          margin-bottom: 0px!important;
        }

        .pdf-audit-title {
          margin-bottom: 0px!important;
          display: none;
        }

        h3 {
          font-size: 20px!important;
        }

        h4 {
          font-size: 18px!important;
        }

        .row > div > div {
          margin-top: 10px!important;
        }


        
        /* Page Numbering Styles */
        @page {
          size: A4;
          margin: 1cm 1cm 2cm 1cm;
          padding: 1cm;
          @bottom-right {
            content: "Page " counter(page);
             font-size: 9pt;
             margin-top: -20px;
          }  
          @bottom-center {
            content: "" !important;
            opacity: 0;
            visibility: hidden;
            display: none;
          }         
          @top{
            content: "" !important;
            opacity: 0;
            visibility: hidden;
            display: none;
          }
          @top-left{
            content: "" !important;
            opacity: 0;
            visibility: hidden;
            display: none;
          }
          @top-right{
            content: "" !important;
            opacity: 0;
            visibility: hidden;
            display: none;
          }
          @top-center{
            content: "" !important;
            opacity: 0;
            visibility: hidden;
            display: none;
          }
        }

     

        
  
        // .pdf-image-row { display: flex; }
             

        // img.audit-image-full { flex: 1; }
      @page {
        size: auto;
        margin: 20mm;
        padding: 1cm;
         @bottom-right {
            content: "Page " counter(page);
            margin-right: 1cm; /* Add margin to move it away from the edge */
            padding-bottom: 4cm; /* Add padding to move it up from the bottom */
        }
      }
      
      @page :first { margin: 0cm; }
      @page :left { margin: 0cm; }
      @page :right { margin: 0cm; }
      .pdf-image-row { display: flex; }

      .row.pdf-image-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        display: none;
      }

      .row.pdf-image-container > div {
        display: grid;
        
      }

      img.audit-image-full { flex: 1; }

        .audit-question-container.border-border.border.rounded-md.p-4.mb-4.shadow-sm {
          margin-top: 30px;
          padding: 0px!important;
          border: 0px !important;
          box-shadow: none !important;
        }

        .btn-standard.pdf-button { display: none; }
        
        textarea.form-control.mt-2.mb-2.pdf-comment__textarea {
          display: none!important;
        }

        p.pdf-comment__paragraph {
          display: block!important;
          border:1px solid #d4d9e5;
          padding: 12px;
          border-radius: 8px;
        }

        .pdf-hide {
          display: none!important;
        }

        .pdf-images {
          display: block!important;
          z-index: 100!important;
        }

        h2.pdf-audit-title {
          margin-bottom: 0px!important;
        }

      }

      /* Ensure page numbers are visible only in print */
      @media screen {
        .page-number {
          display: none;
        }
      }
    `
  });

  return (
    <button 
      onClick={reactToPrintFn} 
      className="flex items-center gap-2.5 btn-standard bg-transparent border border-black hover:bg-[#000!important] hover:text-white pdf-button"
    >
      <AiOutlineDownload size={20} /> <span className="mt-1">Download as PDF</span>
    </button>
  )
}