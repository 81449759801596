import "react-confirm-alert/src/react-confirm-alert.css";

import CheckBox from "../../../shared/components/CheckBox";
import { ERROR_MESSAGE_DURATION } from "../../../shared/Constants";
import Image from "./Image";
import ImageContainer from "./ImageContainer";
import MultiSelectOptions from "./MultiSelectOptions";
import api from "../../../shared/utils/api";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { getUserRole, isUserAdmin } from "../../../shared/utils/authToken";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { PiArrowBendRightDownFill } from "react-icons/pi";
import { AiOutlineSave } from "react-icons/ai";

import _ from "lodash";
import Annotator from "../../ImageAnnotation/Annotator";

import { X } from "lucide-react";
import DatabaseService from "../../../shared/idpHelper";
import Tooltip from "../../../shared/components/Tooltip";

function MediaPreview({ media }) {
  if (media === null) {
    return null;
  } else {
    return (
      <>
        <label className="form-label font-weight-bold">Preview</label>
        <img className="audit-image-full" src={media} alt="Assessment Image" />
      </>
    );
  }
}

function Question({
  index,
  questionID,
  questionContent,
  questionDescription,
  questionAnswers,
  comment,
  supervisorComment,
  supervisorCommentStatus,
  defaultComment,
  images,
  requiresActionValue,
  setCommentCallback,
  homeInspectionCommentID,
  setSupervisorCommentCallback,
  setQuestionOptionsCallback,
  setRequiresActionCallback,
  removeImageCallback,
  auditID,
  homeInspectionID,
  moduleTitle,
  hexCode,
  updateStatusCallback,
  saveInspection,
  showSupervisorComment,
  inspectionReadOnly,
}) {
  const [selectedOptions, setSelectedOptions] = useState(
    questionAnswers
      ?.filter((a) => a.selected)
      .map((a) => a.auditQuestionOptionID),
  );
  const [canEditSupervisorComment, setCanEditSupervisorComment] = useState(
    isUserAdmin() || getUserRole() === 3,
  );
  const [requiresAction, setRequiresAction] = useState(requiresActionValue);
  const [questionComment, setQuestionComment] = useState(
    comment ? comment.replace(/<br\s*\/?>/g, "\n\n") : "",
  );
  const [getSupervisorComment, setSupervisorComment] =
    useState(supervisorComment);
  const [getSupervisorCommentStatus, setSupervisorCommentStatus] = useState(
    supervisorCommentStatus,
  );
  const [useDefaultComment, setUseDefaultComment] = useState(
    questionComment === defaultComment?.replace(/<br\s*\/?>/g, "\n\n"),
  );
  const [imageAttachments, setImageAttachments] = useState(images || []);
  const [previewImageData, setPreviewImageData] = useState(null);

  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [pendingUploads, setPendingUploads] = useState([]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
    };
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Load any pending uploads from IndexedDB
    loadPendingUploads();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const loadOfflineImages = async () => {
      try {
        const offlineImages = await DatabaseService.getAllItems('homeInspectionImages');
        const relevantImages = offlineImages.filter(img =>
          img.auditQuestionID === questionID &&
          img.homeInspectionID === homeInspectionID
        );
        setPendingUploads(relevantImages);
      } catch (error) {
        console.error('Failed to load offline images:', error);
      }
    };

    loadOfflineImages();
  }, [questionID, homeInspectionID]);

  const loadPendingUploads = async () => {
    try {
      const uploads = await DatabaseService.getAllItems('homeInspectionImages');
      const relevantUploads = uploads.filter(
        upload => upload.auditQuestionID === questionID &&
          upload.homeInspectionID === homeInspectionID
      );
      setPendingUploads(relevantUploads);
    } catch (error) {
      console.error('Failed to load pending uploads:', error);
    }
  };

  const handleDeletePendingImage = async (timestamp) => {
    try {
      await DatabaseService.deleteItem('homeInspectionImages', timestamp);
      setPendingUploads(prev => prev.filter(p => p.timestamp !== timestamp));
      toast.success('Pending upload removed');
    } catch (error) {
      toast.error('Failed to remove pending upload');
      console.error('Failed to delete pending upload:', error);
    }
  };

  const handleUseDefaultCommentChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
        if (questionComment && questionComment !== defaultComment?.replace(/<br\s*\/?>/g, "\n\n")) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="confirm-dialog">
                            <h3>Reset Comment</h3>
                            <p>
                                Switching to the default comment will erase your current input.
                                Are you sure?
                            </p>
                            <button
                                className="btn btn-standard float-right"
                                onClick={() => {
                                    const formattedDefault = defaultComment?.replace(
                                        /<br\s*\/?>/g,
                                        "\n\n",
                                    );
                                    setQuestionComment(formattedDefault);
                                    setCommentCallback(questionID, defaultComment);
                                    setUseDefaultComment(true);
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn-standard secondary margin-right-10 float-right"
                                onClick={onClose}
                            >
                                No
                            </button>
                        </div>
                    );
                },
            });
        } else {
            const formattedDefault = defaultComment?.replace(/<br\s*\/?>/g, "\n\n");
            setQuestionComment(formattedDefault);
            setCommentCallback(questionID, defaultComment);
            setUseDefaultComment(true);
        }
    } else {
        setQuestionComment("");
        setCommentCallback(questionID, "");
        setUseDefaultComment(false);
    }
  };

  function groupByHeaderAdvanced(xs, key) {
    if (!xs) return [];
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.values.header === v);

      if (el && v !== "") {
        el.values.options.push(x);
      } else {
        rv.push({ key: rv.length, values: { header: v, options: [x] } });
      }

      return rv;
    }, []);
  }

  const groupedAnswers = _.sortBy(
    groupByHeaderAdvanced(questionAnswers, "header"),
    (a) => a.values.sortOrder,
  );

  const handleOptionChange = (answerID) => {
    // We are UNSELECTING the answer
    if (selectedOptions.includes(answerID)) {
      setSelectedOptions(
        selectedOptions.filter((a) => parseInt(a) !== answerID),
      );
      setQuestionOptionsCallback(questionID, [answerID]);
      return;
    }

    // We are SELECTING the answer
    if (!selectedOptions.includes(answerID)) {
      // We need to check if this answer has a header
      var selectedAnswer = questionAnswers.find(
        (a) => a.auditQuestionOptionID === parseInt(answerID),
      );

      if (selectedAnswer.header === "") {
        // Unselect all other answers and only select this one
        setSelectedOptions([parseInt(answerID)]);
        setQuestionOptionsCallback(questionID, [answerID]);
        return;
      }

      if (selectedAnswer.header !== "") {
        // Unselect all other answers from other headers
        // get all answers from the same header
        var options = groupedAnswers.find(
          (group) => group.values.header === selectedAnswer.header,
        ).values.options;
        var answerIDs = options.map((a) => a.auditQuestionOptionID);
        answerIDs.filter(
          (a) => selectedOptions.includes(a) || a === parseInt(answerID),
        );
        setSelectedOptions(
          answerIDs.filter(
            (a) => selectedOptions.includes(a) || a === parseInt(answerID),
          ),
        );
        setQuestionOptionsCallback(
          questionID,
          answerIDs.filter(
            (a) => selectedOptions.includes(a) || a === parseInt(answerID),
          ),
        );
      }
    }
  };

  const handleCommentChange = (e) => {
    if (useDefaultComment) setUseDefaultComment(false);
    setQuestionComment(e.target.value);
    setCommentCallback(questionID, e.target.value.replace(/\n\n/g, "<br>"));
  };

  const handleRequiresActionChange = (e) => {
    var newVal = !requiresAction;
    setRequiresActionCallback(questionID, newVal);
    setRequiresAction(newVal);
  };

  const removeImage = (imageID) => {
    if (inspectionReadOnly) {
      return;
    }
    // TODO: It would probably be nice to prompt the user if they are sure they want to delete the image
    setImageAttachments(
      imageAttachments.filter((image) => image.imageID !== imageID),
    );
    removeImageCallback(questionID, imageID);
  };

  const addImage = (image) => {
    setImageAttachments([...imageAttachments, image]);
  };

  const handleFileSubmit = (file) => {
    // Prevent duplicate uploads
    if (!file) return;

    const timestamp = new Date().toISOString();
    const imageData = {
      file,
      auditID,
      auditQuestionID: questionID,
      homeInspectionID,
      timestamp
    };

    if (isOffline) {
      try {
        DatabaseService.saveItem('homeInspectionImages', imageData).then(() => {
          setPendingUploads(prev => [...prev, imageData]);
        });
        toast.info('Image saved for upload when online');
      } catch (error) {
        toast.error('Failed to save image for later upload');
        console.error('IndexedDB save failed:', error);
      }
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("auditID", auditID);
      formData.append("auditQuestionID", questionID);
      formData.append("homeInspectionID", homeInspectionID);

      api.postFormData("/upload-home-inspection-image", formData).then(
        (response) => {
          toast.success("File uploaded successfully", { autoClose: 2000 });
          addImage({
            imageID: response.homeInspectionImageID,
            imageURL: response.presignedURL,
          });
          setPreviewImageData(null);
        },
        (error) => {
          toast.error(error.message, { autoClose: ERROR_MESSAGE_DURATION });
        },
      );
    }
  };

  const handleSupervisorCommentChange = (e) => {
    setSupervisorComment(e.target.value);
    setSupervisorCommentCallback(questionID, e.target.value);
    if (
      getSupervisorCommentStatus &&
      getSupervisorCommentStatus !== "Resolved"
    ) {
      setSupervisorCommentStatus("Unresolved");
    }
  };

  const updateSupervisorCommentStatus = async (id) => {
    if (isOffline) {
      toast.info('Status will be updated when back online');
      return;
    }

    try {
      await api.post(`/home-inspection-comments/update-supervisor-comment/${id}`, {});
      const newStatus = getSupervisorCommentStatus === "UnResolved" ? "Resolved" : "UnResolved";
      setSupervisorCommentStatus(newStatus);
      updateStatusCallback(questionID, newStatus);
      toast.success("Comment status updated successfully", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error saving comment", { autoClose: ERROR_MESSAGE_DURATION });
    }
  };

  useEffect(() => {
    updateStatusCallback(questionID, getSupervisorCommentStatus);
  }, [getSupervisorCommentStatus]);
  return (
    <div id={`question-${questionID}`} className="">
      {getSupervisorCommentStatus === "UnResolved" && (
        <div className="text-orange-600 text-base mb-2 mt-4">
          This question has unresolved feedback{" "}
          <PiArrowBendRightDownFill className="h-4 w-4 inline-block mr-2" />
        </div>
      )}
      <div
        className="audit-question-container border-border border rounded-md p-4 mb-4 shadow-sm"
        key={questionID}
      >
        {moduleTitle && hexCode && (
          <span
            className="inline-block rounded-md mr-2 text-sm font-bold px-2.5 py-[0.125rem] pt-1 mb-3"
            style={{ backgroundColor: `#${hexCode}` }}
          >
            {moduleTitle}
          </span>
        )}
        <h4 className="flex flex-row gap-2 flex-nowrap">
          <span className="inline-block">{index + 1}.</span>
          <span className="inline-block">
            {questionContent}
            {questionDescription && (
              <Tooltip content={questionDescription || "No description available"} />
            )}
          </span>
        </h4>
        <div className="row gy-2 gx-1">
          <div className="audit-options-container page-break flex col-gap-20 mb-2 gap-y-4">
            {groupedAnswers.map((group, index) => (
              <MultiSelectOptions
                disabled={inspectionReadOnly}
                questionID={questionID}
                header={group.values.header}
                options={group.values.options}
                selectedOptions={selectedOptions}
                updateCallback={handleOptionChange}
                //className="!bg-gray-100 border-border border-[1px]"
                // index={index}
                className={`border-border border-[1px] ${index === 0
                  ? "!border-green-400"
                  : index === 1
                    ? "!border-red-300"
                    : index === 2
                      ? "!border-blue-500"
                      : ""
                  }`}
              />
            ))}
          </div>
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <label className="form-label font-weight-bold mb-1">
                Comment
              </label>

              {defaultComment && (
                <div className="form-check form-check-inline pdf-hide">
                  <label className="form-check-label">
                    <input
                      name={"useDefaultComment" + questionID}
                      className="form-check-input"
                      type="checkbox"
                      checked={useDefaultComment}
                      onChange={handleUseDefaultCommentChange}
                      disabled={inspectionReadOnly}
                    />
                    Use default comment
                  </label>
                </div>
              )}
            </div>
            <textarea
              className="form-control mt-2 mb-2 pdf-comment__textarea pdf-hide"
              rows="3"
              value={questionComment}
              onChange={handleCommentChange}
              disabled={inspectionReadOnly}
            ></textarea>
            <p style={{ display: "none" }} className="pdf-comment__paragraph">
              {questionComment || "None"}
            </p>
          </div>
          <div className="form-group mb-2">
            <CheckBox
              label="Requires Action"
              bold
              checked={requiresAction}
              onChange={handleRequiresActionChange}
              disabled={inspectionReadOnly}
            />
          </div>
          {!inspectionReadOnly && (
            <>
              <label className="font-bold pdf-hide">Upload Images</label>
              <div className="mt-2 mb-3 flex gap-3 pdf-hide">
                <Annotator handleFileSubmit={handleFileSubmit} />
              </div>
              <MediaPreview media={previewImageData} />
            </>
          )}
          {pendingUploads.length > 0 && (
            <div className="mb-4 pdf-hide">
              <h5 className="text-sm font-medium text-amber-600 mb-2">
                Pending Uploads (Will sync when online):
              </h5>
              <div className="flex flex-wrap gap-2">
                {pendingUploads.map((upload) => (
                  <div key={upload.timestamp} className="relative">
                    <img
                      src={URL.createObjectURL(upload.file)}
                      alt="Pending upload"
                      className="w-20 h-20 object-cover rounded border border-amber-300"
                    />
                    <button
                      onClick={() => handleDeletePendingImage(upload.timestamp)}
                      className="absolute -right-2 -top-2 bg-gray-200 hover:bg-gray-300 rounded-full p-1"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <MediaPreview media={previewImageData} />
          <div>
            <ImageContainer>
              {imageAttachments.map((image) => (
                //TODO: Maybe if the audit is completed it renders full images instead of the previews?
                <Image
                  key={image.imageID}
                  imageURL={image.imageURL}
                  removeImageCallback={() => removeImage(image.imageID)}
                  readOnly={inspectionReadOnly}
                />
              ))}
            </ImageContainer>
          </div>
          <div className="pdf-images__container" style={{ display: "none" }}>
            {imageAttachments.map((image) => (
              <img src={`${image.imageURL}`} className="pdf-image-container__image" alt="Uploaded Image" />
            ))}
          </div>
          {/* <div style={{ display: 'none' }} className="pdf-images">
            <ImageContainer>
              {imageAttachments.map((image) => (
                <ImageFull imageURL={image.imageURL} />
              ))}
            </ImageContainer>
          </div> */}

          {showSupervisorComment && (
            <div className="form-group z-20">
              <label
                htmlFor="supervisorComment"
                className="form-label font-weight-bold"
              >
                Reviewer Comment
              </label>
              <textarea
                className="form-control pdf-hide"
                rows="3"
                value={getSupervisorComment}
                disabled={canEditSupervisorComment !== true}
                onChange={handleSupervisorCommentChange}
              ></textarea>
              <p key={getSupervisorComment} style={{ display: 'none' }} className="pdf-comment__paragraph ">
                {getSupervisorComment || "None"}
              </p>
            </div>
          )}
          {showSupervisorComment && !inspectionReadOnly && (
            <div className="form-group pdf-hide">
              {canEditSupervisorComment && getSupervisorCommentStatus === "" && (
                <button
                  className="btn btn-standard btn-outline mt-1 pdf-button"
                  onClick={() => saveInspection()}
                  disabled={isOffline}
                >
                  <AiOutlineSave className="mr-1.5 inline-block" />
                  Save Comment
                  {isOffline && " (Available when online)"}
                </button>
              )}

              {getSupervisorCommentStatus === "UnResolved" && (
                <button
                  className="btn btn-standard btn-outline w-auto pdf-hide"
                  onClick={() => updateSupervisorCommentStatus(homeInspectionCommentID)}
                  disabled={isOffline}
                >
                  <IoIosCheckmarkCircleOutline className="h-5 w-5 inline-block mr-2" />
                  Feedback Addressed
                  {isOffline && " (Available when online)"}
                </button>
              )}

              {getSupervisorCommentStatus === "Resolved" && (
                <button
                  className="btn btn-standard btn-outline w-auto pdf-hide"
                  onClick={() => updateSupervisorCommentStatus(homeInspectionCommentID)}
                  disabled={isOffline}
                >
                  <AiOutlineExclamationCircle className="h-5 w-5 inline-block mr-2" />
                  Mark as Unresolved
                  {isOffline && " (Available when online)"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Question;
