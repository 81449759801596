import { Tooltip } from 'react-tooltip';
import { BsQuestionCircle } from "react-icons/bs";
import { useState, useEffect, useCallback } from 'react';

function TooltipComponent({ content, id }) {
    const [isMobile, setIsMobile] = useState(false);
    const tooltipId = id || `tooltip-${Math.random().toString(36).slice(2, 11)}`;

    const checkMobile = useCallback(() => {
        setIsMobile(window.matchMedia('(max-width: 1025px)').matches);
    }, []);

    useEffect(() => {
        checkMobile();
        const mediaQuery = window.matchMedia('(max-width: 1025px)');
        mediaQuery.addEventListener('change', checkMobile);
        return () => mediaQuery.removeEventListener('change', checkMobile);
    }, [checkMobile]);

    return (
        <div className="relative inline-block">
            <BsQuestionCircle
                className="inline-block ml-2 text-black cursor-pointer w-4 h-4 duration-300 bg-[rgb(239,202,0)] hover:bg-white rounded-full"
                data-tooltip-id={tooltipId}
                data-tooltip-content={content}
                data-tooltip-event={isMobile ? "click" : "hover"}
            />

            <Tooltip
                id={tooltipId}
                place="bottom"
                className="!w-52 lg:!w-80 !p-4 !text-sm !bg-black !rounded-lg z-10"
                classNameArrow="!w-4 !h-4 !bg-black"
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                }}
                openOnClick={isMobile}
                clickable={true}
            />
        </div>
    );
}

export default TooltipComponent; 